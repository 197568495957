@use "sass:math";

// Подключение миксинов ===========================================================================================================================================================================================================================================================================
@import "mixins";

// Подключение шрифтов ===========================================================================================================================================================================================================================================================================
//&display=swap - добавить при подключении через плагин

// Подключить если есть локальные файлы шрифтов
//@import "fonts/fonts";

// Подключение иконочных шрифтов ==============================================================================================================================================================================================================================================================================
// Подключить если есть файл иконочного шрифта
//@import "fonts/icons";

// ============================================================================================================================================================================================================================================================================================================================================================================================
// Настройки шаблона ============================================================================================================================================================================================================================================================================================================================================================================================
// ============================================================================================================================================================================================================================================================================================================================================================================================

// Шрифт по умолчанию ==============================================================================================================================================================================================================================================================================
$fontFamily: "Montserrat";
$fontSize: rem(18); // где 14(px) - размер шрифта по умолчанию из макета

// Основные цвета
$mainColor: #252525; // Цвет шрифта по умолчанию

// Настройки адаптивной сетки ===============================================================================================================================================================================================================================================================================================

// Минимальная ширина страницы
$minWidth: 320;
// Ширина полотна (макета)
$maxWidth: 1920;
// Ширина ограничивающего контейнера (0 = нет ограничения)
$maxWidthContainer: 1170;
// Общий отступ у контейнера
// (30 = по 15px слева и справа, 0 = нет отступа)
$containerPadding: 30;

// Ширина срабатывания первого брейкпоинта
$containerWidth: $maxWidthContainer + $containerPadding;

// Брейк-поинты
$pc: em($containerWidth); // ПК, ноутбуки, некоторые планшеты в горизонтальном положении
$tablet: em(991.98); // Планшеты, некоторые телефоны в горизонтальном положении
$mobile: em(767.98); // Телефоны L
$mobileSmall: em(479.98); // Телефоны S

// Тип адаптива:
// 1 = отзывчивость (у контейнера нет брейкпоинтов),
// 2 = по брейк-поинтам (контейнер меняет свою ширину по брейк-поинтам)
$responsiveType: 1;

// ============================================================================================================================================================================================================================================================================================================================================================================================
// ============================================================================================================================================================================================================================================================================================================================================================================================

// Обнуление ============================================================================================================================================================================================== =============================================================================================== ===============================================================================================
@import "null";

// Стили тега BODY ============================================================================================================================================================================================================================================================================================================================================================================================

body {
	// Скролл заблокирован
	.lock & {
		overflow: hidden;
		touch-action: none;
		overscroll-behavior: none;
	}
	// Сайт загружен
	//.loaded & {}
}

// Оболочка wrapper ============================================================================================================================================================================================================================================================================================================================================================================================================================================
.wrapper {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	// Прижимаем footer
	> .main {
		flex: 1 1 auto;
	}
	// Фикс для слайдеров
	> * {
		min-width: 0;
	}
}
// Ограничивающий контейнер ======================================================================================================================================================================================================================

@if ($responsiveType==1) {
	// Отзывчивая
	[class*="__container"] {
		@if ($maxWidthContainer>0) {
			max-width: rem($maxWidthContainer);
			box-sizing: content-box;
			margin: 0 auto;
		}
		@if ($containerPadding>0) {
			padding: 0 rem(math.div($containerPadding, 2));
		}
	}
} @else {
	// По брейк-поинтам
	[class*="__container"] {
		margin: 0 auto;
		@if ($maxWidthContainer>0) {
			max-width: rem($maxWidthContainer);
			box-sizing: content-box;
		} @else {
			@if ($containerPadding>0) {
				padding: 0 rem(math.div($containerPadding, 2));
			}
		}
		@media (max-width: $pc) {
			max-width: rem(970);
		}
		@media (max-width: $tablet) {
			max-width: rem(750);
		}
		@media (max-width: $mobile) {
			max-width: none;
			@if ($containerPadding>0 and $maxWidthContainer>0) {
				padding: 0 rem(math.div($containerPadding, 2));
			}
		}
	}
}

@import "app";
