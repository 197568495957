.body {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    @media(max-width: 379.98px) {
        margin-bottom: 10px;
    }
}

.addressForm {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.formLine,
.formLineSelect {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    @media(max-width: 479.98px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5px;
    }        
}

.formLineSelect {
    position: relative;
    user-select: none;
}

.arrow,
.rotate {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    cursor: pointer;
    transition: fill 0.4s ease 0s;

    &:hover {
        fill: #AD912B;
    }

    @media(max-width: 479.98px) {
        left: 170px;
        top: 30px;
    }

    @media(max-width: 379.98px) {
        left: 150px;
        top: 26px;
    }
}

.rotate {
    transform: rotate(-180deg);
}

.formLineCheckbox {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0;
}

.formLabel,
.formLabelCheckbox,
.formLabelChecked {
    font-size: 15px;
    font-weight: 500;
    color: #252525;
    user-select: none;
    @media(max-width: 379.98px) {
        font-size: 13px;
    }
}

.formLabelCheckbox,
.formLabelChecked {
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border: 2px solid #7f766a;
        background-color: #fff;
        right: -34px;
        border-radius: 5px;
    }
}

.formLabelChecked {
    &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        background-color: #7f766a;
        right: -30px;
    }
}

.formInput,
.formInputSelect {
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 20px;
    border: 1.5px solid #9c9c9c;
    outline: none;
    @media(max-width: 379.98px) {
        font-size: 12px;
    }
}

.formInputSelect {
    cursor: pointer;
    z-index: 2;
}

.formInputCheckbox {
    position: absolute;
    opacity: 0;
}

.formBtnSubmit {
    display: flex;
    justify-content: center;
    padding: 15px 20px;
    border-radius: 20px;
    background-color: #fff;
    font-size: 16px;
    font-weight: 600;
    color: #1560BD;
    border: 2px solid #1560BD;
    box-shadow: 3px 3px 3px #9c9c9c56;
    transition: all 0.6s ease 0s;

    &:hover {
        color: #fff;
        border: 2px solid #1560BD;
        background-color: #1560BD;
    }

    @media(max-width: 379.98px) {
        font-size: 14px;
    }
}

.countriesWrapper {
    position: absolute;
    top: 18px;
    right: 0;
    width: 196px;
    height: 220px;
    display: flex;
    flex-direction: column;
    border: 1.5px solid #9c9c9c;
    border-radius: 0 0 20px 20px;
    overflow: hidden;
    @media(max-width: 479.98px) {
        left: 0;
        top: 34px;
    }

    @media(max-width: 379.98px) {
        width: 176px;
    }
}

.countriesList {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    background-color: #fafafa;
    border-radius: 0 0 20px 20px;
    color: #484848;
    z-index: 1;
}

.searchLine {
    position: relative;
}

.searchSvg {
    position: absolute;
    top: 8px;
    left: 10px;
    width: 18px;
    height: 18px;
    fill: #25252594;
}

.countrySearch {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 8px 10px 8px 40px;
    letter-spacing: 0.5px;
    outline: none;

    &::placeholder {
        color: #848484;
    }
    @media(max-width: 379.98px) {
        font-size: 12px;
    }
}

.countryItem {
    font-size: 14px;
    font-weight: 500;
    padding: 8px 10px;
    transition: all 0.4s ease 0s;
    cursor: pointer;

    &:hover {
        background-color: #9c9c9c0d;
        color: #AD912B;
    }

    @media(max-width: 379.98px) {
        font-size: 12px;
    }
}

.hidden {
    display: none;
}


