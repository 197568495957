@import 'variables.scss';

#root {
    @media(max-width: 991.98px) {
        min-height: 100vh;        
    }
}

.header {
    display: flex;
    flex-direction: column;        
}

.header-logo {
    &__image {
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        width: 50px;
        height: 50px;            
        @media (max-width: 767.98px) {
            width: 40px;
            height: 40px;
        }
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}

.body-header {
    background-color: #fff;
    position: relative;
    width: 100%;
    &_fixed {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 50;
        border-bottom: 2px solid #2525250c;
        box-shadow: 5px 5px 5px #4848480d;        
    }
    &__container {
        position: relative;
        min-height: 80px;    
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 15px;
        @media (max-width: 767.98px) {
            min-height: 60px;
            gap: 10px;
        }
    }
    &__logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        font-size: 12px;
        @media (max-width: 479.98px) {
            gap: 10px;
        }
    }
    &__text {
        font-family: 'Crimson Text', serif;
        font-size: 1.4rem;
        letter-spacing: 0.8px;         
        font-weight: 600;
        color: #484848;
        text-transform: uppercase;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        @media (max-width: 382.98px) {                                    
            font-size: 1rem;
            letter-spacing: 0.5px;
            width: 140px;
        }
        @media (max-width: 321.98px) {
            font-size: 0.6rem;       
            width: 90px;
        }
    }
    &__actions {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        @media (max-width: 479.98px) {
            gap: 15px;
        }

        ._icon-cart {
            position: relative;
            width: 25px;
            height: 25px;
            fill: #252525;
            transition: fill 0.5s ease 0s;
            span {
                position: absolute;
                display: flex;
                top: 0;
                left: 40%;
                z-index: 2;                
                font-size: 0.6rem;                
                font-weight: 700;
                color: #000000;
                text-align: center;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                background-color: $decorColor;
                justify-content: center;
                align-items: center;
                color: $whiteColor;
            }
            &:hover {
                fill: #000;
            }

                                                                        
        }
        ._icon-user {
            cursor: pointer;
            width: 25px;
            height: 25px;
            fill: #252525;
            transition: fill 0.5s ease 0s;
            &:hover {
                fill: #000;
            }
        }
    }
}

.popup-header {
    display: none;
    position: absolute;
    bottom: -50px;
    right: 0;
    width: 110px;
    height: 64px;
    background-color: #fff;
    border-bottom: 1px solid #d9d9d979;
    border-left: 1px solid #d9d9d979;
    border-right: 1px solid #d9d9d979;
    box-shadow: 3px 3px 3px #d9d9d945;
    border-radius: 0 0 20px 20px;
    &._active {
        display: flex;
    }
    &__content {
        padding: 10px;
    }
    &__list {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    &__item {
        display: flex;
        gap: 10px;
        font-size: 16px;
        transition: all 0.6s ease 0s;
        color: #484848;
        &:hover {
            color: #AD912B;
            svg {
                fill: #AD912B;
            }
        }
        svg {
            transition: all 0.6s ease 0s;
            width: 15px;
            fill: #484848;
        }
        &:last-child {
            cursor: pointer;
        }
    }

}

.search-header {
    &__form {
        display: flex;
        justify-content: center;
        align-items: center;        
        position: relative;
        ._icon-search {
            width: 20px;
            height: 20px;
            fill: #b4b4b4;
        }
        @media (max-width: 767.98px) {
            position: absolute;
            top: 63px;
            right: 10px;
        }
    }
    &__input {
        width: 260px;
        height: 40px;
        border-radius: 20px;
        background-color: #ffffff;
        border: 2px solid #d9d9d97e;
        font-size: 1rem;
        letter-spacing: 0.5px;
        padding: 0 30px 0 40px;
        transition: all 0.6s ease 0s;
        @media (max-width: 767.98px) {
            width: 200px;
            height: 33px;
            border: 2px solid #ffffffe8;
            background-color: inherit;
        }
        &:focus,
        &:active {    
            border: 2px solid #252525;
            outline: none;        
            @media (max-width: 767.98px) {      
                border: 2px solid #ffffffe8;
                background-color: #ffffff63;
            }
        }

    }
    &__button {
        position: absolute;
        top: 25%;
        left: 13px;
        @media (max-width: 767.98px) {
            left: 10px;
        }
    }
    &__results {
        display: flex;
        position: absolute;
        top: 130px;
        left: 0;
        width: 100%;        
        max-height: 75vh;
        overflow: auto;   
        background-color: #fff;
        border-top: none;
        z-index: 50;            
        box-shadow: 0px 5px 5px #7f756a0f;
        padding: 10px 0;
        &_hidden {
            display: none;
        }
        &::-webkit-scrollbar {
            display: none;
        }
        @media(max-width: 767.98px) {
            top: 100px;
        }
        @media(max-width: 579.98px) {
            border-bottom: 2px solid #7f766a44;
        }
    }
    &__body {
        width: 100%;
        height: 100%;
        overflow-y: auto;
    }
}

.search-list {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    &__item {
        font-size: 14px;
        font-weight: 500;
        color: #484848;
        line-height: 1.2;
        cursor: pointer;
        transition: background-color 0.6s ease 0s;
        padding: 5px 15px;
        h2 {
            transition: color 0.6s ease 0s;
        }
        &:hover {
            h2 {
                color: #AD912B;
            }
            background-color: #fefbfb;
        }
        &_none {
            display: flex;
            justify-content: center;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.5px;
            padding: 30px 15px 5px 15px;
            border-top: 2px solid #25252517;
        }
        @media(max-width: 767.98px) {
            font-size: 12px;
        }
    }
}

.item-search {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    @media(max-width: 579.98px) {
        gap: 15px;
    }
    &__image {
        width: 100px;
        height: 100px;
        position: relative;
        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        @media(max-width: 767.98px) {
            width: 80px;
            height: 80px;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        gap: 5px;
        @media (max-width: 767.98px) {
            max-width: 400px;
        }
        @media (max-width: 579.98px) {
            max-width: 250px;
        }
        @media (max-width: 479.98px) {
            max-width: 180px;
        }
        @media (max-width: 379.98px) {
            max-width: 150px;
        }
        @media (max-width: 319.98px) {
            max-width: 130px;
        }
    }
}

.info-search {
    &__price-block {
        display: flex;
        align-items: center;        
        row-gap: 5px;
        column-gap: 15px;
        flex-wrap: wrap;
    }
    &__strike {
        text-decoration: line-through;
        color: #848484;
    }
    &__promo {
        &_red {
            color: #b45a40;
        }
    }
    &__percents {
        position: absolute;
        top: 5px;
        left: 15px;
        display: flex;
        font-size: 10px;        
        padding: 3px 5px;
        background-color: #e53638;
        color: #fff;
        &_black {
            background-color: transparent;
            span {
                background-color: #e53638;      
                font-size: 10px;
                padding: 3px 5px;
            }

        }
    }
}

.top-header {
    background-color: #252525;
    &__container {
        height: 30px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media (max-width: 767.98px) {
            height: 24px;
        }
    }
    &__ticker {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 20px;
    }
    &__hidden {
        display: none;
    }
}

@keyframes ad_pc {
    0% {
        left: 50%;
        opacity: 0;
    }
    10% {
        opacity: 80%;
    }
    50% {
        opacity: 100%;
    }
    96% {
        opacity: 90%;
    }
    100% {
        left: 0;
        opacity: 0;
    }
}

@keyframes ad_mobile {
    0% {
        left: 50%;
        opacity: 0;
    }
    10% {
        opacity: 100%;
    }
    100% {
        left: -35%;
        opacity: 80%;
    }
}

.ticker-header {
    &__text {
        display: block;
        position: relative;
        color: #fff;
        font-family: "Raleway";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1.5px;
        width: 100%;
        height: 30px;
        overflow: hidden;
        span {
            position: absolute;
            bottom: 30%;
            left: 0;
            width: 350px;
            animation: ad_pc 20s linear infinite 0.5s;
            &:hover {
                animation-play-state: paused;
            }
        }
        @media (max-width: 991.98px) {
            span {
                animation: ad_mobile 20s linear infinite 0.5s;
            }
        }
        @media (max-width: 767.98px) {
            font-size: 12px;
            span {
                animation: ad_mobile 17s linear infinite 0.5s;
            }
        }
        @media (max-width: 479.98px) {
            span {
                animation: ad_mobile 12s linear infinite 0.5s;
            }
        }
    }
    &__btn {
        position: relative;
        cursor: pointer;        
        span {     
            display: block;
            width: 18px;
            height: 18px;
            &::before,
            &::after {
                content: "";  
                position: absolute;
                top: 8px;
                right: 0;
                width: 18px;
                height: 2px;
                background-color: #ffffff7f;             
                transition: all 0.3s ease 0s;
                @media(max-width: 767.98px) {
                    width: 14px;
                    right: 2px;
                }
            }
            &::before {
               transform: rotate(45deg);  
            }
            &::after {
                transform: rotate(-45deg);                
            }
            &:hover {
                &::before,
                &::after {
                    background-color: #fff;
                }
            }
        }
    }
}

.bottom-header {
    background-color: #f1ede7;
    font-family: "Raleway";
    &__container {
        min-height: 50px;
        margin: 0 auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media (max-width: 767.98px) {
            min-height: 40px;
        }
    }
}


.menu {
    &__body,
    &__shift {
        position: fixed;
        left: -104%;
        width: 40%;
        height: 100%;
        overflow: auto;
        background-color: #fff;
        box-shadow: 5px 5px 5px #48484825;
        padding: 100px 0px 30px 0px;
        transition: left 0.8s ease 0s;
        z-index: 10;
        @media (max-width: 991.98px) {
            width: 60%;
        }

        @media (max-width: 579.98px) {
            width: 100%;
        }
        &::before {
            content: "";
            position: fixed;
            left: -104%;
            width: 40%;
            height: 50px;
            background-color: #f1ede7;
            transition: left 0.8s ease 0s;
            z-index: 10;
            @media (max-width: 991.98px) {
                width: 60%;
            }

            @media (max-width: 579.98px) {
                width: 100%;
            }
        }
    
        .menu-open & {
            left: 0;

            &::before {
                left: 0;
            }
        }
    }

    &__body { 
        top: 80px;
        &::before {
            top: 80px;
            @media (max-width: 767.98px) {
                height: 40px;
                top: 60px;
            }
        }
        @media (max-width: 767.98px) {
            top: 60px;
        }
    }
    &__shift {
        top: 110px;
        &::before {
            top: 110px;
            @media (max-width: 767.98px) {
                height: 40px;
                top: 84px;
            }
        }
        @media (max-width: 767.98px) {
            top: 84px;
        }
    }
        

    &__link {
        display: flex;
        gap: 20px;
        margin-left: 10px;
        justify-content: space-between;
        align-items: center;
        &_hidden {
            display: none;
        }
        @media (max-width: 767.98px) {
            margin-left: 5.5px;
        }
    }
    &__list {        
        display: flex;
        flex-direction: column;
        padding-bottom: 100px;
    }
    &__item {
          &:hover {
            color: #000;
            background-color: #fefbfb;
          }
    }
}

.item-menu {
    &__link {
        display: flex;
        &-promo {
            color: #AD902B;
        }
    }
    &__button {
        display: flex;        
        font-family: "Raleway";
        justify-content: space-between;
        align-items: center;
        width: 100%;        
        font-size: 20px;
        text-transform: uppercase;
        line-height: 130%;
        letter-spacing: 0.5px;
        font-weight: 500;
        padding: 15px 40px 15px 60px;        
        gap: 20px;  
        @media (max-width: 767.98px) {
            padding: 10px 25px;
            font-size: 18px;
        }  
        @media (max-width: 372.98px) {
            gap: 10px;
            font-size: 15px;
        }  
        svg {
            width: 10px;
            fill: #AD902B;
        }    
    }
}


.icon-menu {
    display: block;
    position: relative;
    width: 30px;
    height: 18px;
    flex: 0 0 30px;
    cursor: pointer;
    z-index: 11;

    &__text {
        font-weight: 600;  
        z-index: 11;             
        &_hidden {     
            font-size: 18px;  
            letter-spacing: 0.8px;
            text-transform: uppercase;    
            &:hover {
                color: #000;
            }
            @media (max-width: 479.98px) {
                display: none;   
            }            
        }
        &_show {
            position: absolute;
            top: 60px;
            left: 25px;
            font-size: 20px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #252525;
            z-index: 1;
            &:hover {
                color: #000;
            }
            @media (min-width: 479.98px) {
                display: none;
            }
            @media (max-width: 371.98px) {
                font-size: 16px;
            }
        }

    }

    span,
    &::before,
    &::after {
        content: "";
        transition: all 0.6s ease 0s;
        right: 0;
        position: absolute;
        width: 100%;
        height: 0.125rem;
        background-color: #000;
    }

    &::before {
        top: 0;
    }

    &::after {
        bottom: 0;
    }

    span {
        top: calc(50% - 1px);
    }

    .menu-open & {
        span {
            width: 0;
        }

        &::before {
            top: calc(50% - 1px);
            transform: rotate(-45deg);
        }

        &::after {
            bottom: calc(50% - 1px);
            transform: rotate(45deg);
        }
    }
    
}

.sub-menu {
    &__skeleton {
        margin-left: 30px;
        @media(max-width: 767.98px) {
            margin-left: 0px;
        }
    }
    &__all {
        position: absolute;
        top: 70px;
        left: 59px;
        color: #AD902B;
        font-weight: 600;
        font-size: 24px;
        cursor: pointer;
        transition: text-decoration 0.6s ease 0s;
        &:hover {
            text-decoration: underline;
        }
        @media (max-width: 767.98px) {
            left: 25px;
        }
        @media(max-width: 372.98px) {
            font-size: 20px;
        }
    }
    &__body,
    &__shift {
        position: fixed;
        left: -104%;
        width: 40%;
        height: 100%;
        overflow: auto;
        background-color: #fff;
        padding: 120px 0px 30px 0px;
        transition: left 0.8s ease 0s;
        z-index: 10;

        @media (max-width: 991.98px) {
            width: 60%;
        }

        @media (max-width: 579.98px) {
            width: 100%;
        }

        &::before {
            content: "";
            position: fixed;
            left: -104%;
            width: 40%;
            height: 50px;
            background-color: #f1ede7;
            transition: left 0.8s ease 0s;
            z-index: 10;

            @media (max-width: 991.98px) {
                width: 60%;                
            }

            @media (max-width: 579.98px) {
                width: 100%;
            }
        }

        .sub-menu-open & {
            left: 0;

            &::before {
                left: 0;
            }
        }
    }

    &__body{
        top: 100px;
        &::before {
            top: 80px;
            @media (max-width: 767.98px) {
                top: 60px;
                height: 40px;
            }
        }
        @media (max-width: 767.98px) {
            top: 80px;
        }
    }
    &__shift {
        top: 130px;
        &::before {
            top: 110px;
            @media (max-width: 767.98px) {
                top: 84px;
                height: 40px;
            }
        }
        @media (max-width: 767.98px) {
            top: 104px;
        }
    }

    &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 767.98px) {
            margin-left: 5.5px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        font-size: 20px;
        letter-spacing: 0.5px;
        line-height: 130%;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 60px;
        @media (max-width: 372.98px) {
            font-size: 18px;
        }
        @media (max-width: 372.98px) {
            font-size: 15px;
        }
    }

    &__item {
        a:hover {
            color: #000;
            background-color: #fefbfb;
        }
    }
}

.item-sub-menu__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 15px 40px 15px 60px;

    @media (max-width: 767.98px) {
        padding: 10px 25px;
    }

}

.list-sub-menu__back {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 140px;        
    margin-right: 40px;     
    @media (max-width: 579.98px) {
        margin-right: 20px;
    }
}

.back__button {
    display: flex;    
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: #AD902B;    
    gap: 15px;
    svg {
        width: 18px;
        fill: #AD902B;
    }    
    &:hover {
        text-decoration: underline;
    }
}

.main {
    @media (min-width: 992px) {
        min-height: 90vh;
    }
}

.not-found {
    &__content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        align-items: center;
        padding: 30px 0 80px 0;
        gap: 30px;    
        @media (max-width: 991.98px) {
            padding: 40px 0;
            gap: 20px;
        } 
        &-product {
            padding: 0 0 60px 0;
            @media (max-width: 991.98px) {
                padding: 0 0 40px 0;
            }
        }          
    }
    &__body {
        display: flex;
        flex-direction: column;
        gap: 40px;
        @media (max-width: 479.98px) {
            gap: 20px;
        }
    }
    &__title {
        font-size: 24px;
        font-weight: 500;
        color: #484848;
        @media (max-width: 991.98px) {
            font-size: 20px;
        }
        @media (max-width: 479.98px) {
            font-size: 16px;
        }
    }
    &__link {
        font-size: 16px;
        @media (max-width: 991.98px) {
            font-size: 14px;
        }
    
        @media (max-width: 479.98px) {
            font-size: 12px;
        }
    }
    &__image {
        display: flex;
        position: relative;
        width: 240px;
        height: 240px;
        @media (max-width: 767.98px) {
            width: 200px;
            height: 200px;
        }
        @media (max-width: 479.98px) {
            width: 180px;
            height: 180px;
        }
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0px;
            object-fit: cover;
        }
    }  
    &__link-back {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-size: 20px;
        color: #AD902B;
        &:hover {
            text-decoration: underline;
        }
        svg {
            width: 10px;
            fill: #AD902B;
        }
        @media (max-width: 479.98px) {
            font-size: 16px;
        }
    }  
}

.login-main {
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 80px 0;
        @media (max-width: 767.98px) {
            padding: 40px 0;
        }
    }
    &__text {
        display: flex;    
        font-size: 14px;           
        font-weight: 500;
        letter-spacing: 0.5px;
        color: #484848;
        gap: 10px;
        margin-bottom: 20px;
        @media (max-width: 479.98px) {
            flex-direction: column;
            text-align: center;
        }
    }
    &__link {
        color: #AD902B;
        &:hover {
            text-decoration: underline;
        }
    }
}

.form-login {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 30px;
    &__title {
        max-width: 400px;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 1.4;
        color: #48484880;
        margin-bottom: 50px;
        text-align: center;
        @media (max-width: 479.98px) {
            letter-spacing: normal;
            font-size: 14px;
            max-width: 280px;
        }
    }
    &__line {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        &:not(:last-child) {
            margin-bottom: 30px;
        }
        @media (max-width: 479.98px) {
            flex-direction: column;
            align-items: flex-start;
        }
        &_checkbox {
            position: relative;
            align-items: start;
            border-bottom: none;
        }
        &_error,
        &_err {
            display: none;
            justify-content: flex-end;
            border-bottom: none;
            font-weight: 500;                
            color: #e53638;
            letter-spacing: 2px;
            font-size: 14px;
            line-height: 1.2;
            margin-top: -20px;
            @media(max-width: 479.98px) {
                font-size: 12px;
                letter-spacing: normal;
            }
            &._error {
                display: flex;            
            }
        }
        svg {
            position: absolute;
            top: 5px;
            right: 0;
            fill: #484848;
            width: 22px;
            height: 22px;
            cursor: pointer;
            @media (max-width: 479.98px) {
                top: 30px;
            }
        }
    }
    &__label {
        font-size: 14px;
        line-height: 1.2;
        font-weight: 500;
        span {
            color: #AD902B;
        }
    }
    &__input {
        font-size: 13px;
        height: 30px;
        padding: 10px;
        outline: none;
        width: 48%;
        border-bottom: 2px solid #d9d9d9a4;
        &_access {
            width: 60%;
        }
        @media(max-width: 479.98px) {
            width: 100%;
        }
    }
    &__text {
        font-size: 14px;
        font-style: italic;
        color: #484848;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    &__checkbox {
        position: absolute;
        opacity: 0;
        top: 30px;
        right: 15px;
    }
    &__button {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1.5px;
        align-self: center;
        width: 100%;
        padding: 10px 20px;
        border-radius: 20px;
        border: 2px solid #484848;
        box-shadow: 3px 3px 3px #d9d9d962;
        margin-top: 20px;
        transition: all 0.6s ease 0s;
        color: #848484;
        cursor: default;
        &._active {
            border: 2px solid $decorDarkColor;
            color: #252525;
            cursor: pointer;
        }
        &._active:hover {
            background-color: $decorDarkColor;
            color: #fff;            
        }
    }
}

.checkbox {
    &-label {
        position: relative;
        display: inline-flex;
        flex-direction: row-reverse;
        gap: 5px;
        margin-bottom: 30px;
        width: 100%;
        justify-content: space-between;
        cursor: pointer;
        @media(max-width: 479.98px) {
            font-size: 15px;
        }
        &::before {
            content: "";
            flex: 0 0 20px;
            height: 20px;
            top: -1px;
            right: 0;
            border: 2px solid #AD902B;
            border-radius: 5px;
            margin-right: 10px;
        }
        &::after {
            position: absolute;
            content: "";
            width: 12px;
            height: 12px;
            top: 4px;
            right: 4px;
            background-color: #AD902B;
            border-radius: 2px;
            transform: scale(0);
            margin-right: 10px;
        }
        &._checked::after {
            transform: scale(1);
        }
    }
}

.account-main {
    &__content {
        padding: 60px 0 80px 0;
        @media (max-width: 767.98px) {
            padding: 30px 0 60px 0;
        }
    }
    &__title {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 15px;
        text-align: center;
        span {
            white-space: nowrap;
            color: $goldColor;
        }
        @media (max-width: 767.98px) {
            font-size: 18px;
        }
        @media (max-width: 379.98px) {
            font-size: 16px;
            margin-bottom: 8px;
        }
    }
    &__subtitle {
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 18px;
        justify-content: center;
        color: #484848;
        margin-bottom: 100px;
        @media (max-width: 767.98px) {
            font-size: 16px;
            margin-bottom: 40px;
        }
        @media (max-width: 379.98px) {
            font-size: 12px;
            margin-bottom: 20px;
        }
        svg {
            width: 20px;
            fill: #e4c2c2;
            @media (max-width: 767.98px) {
                width: 16px;
            }
        }
    }
    &__body {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        width: 100%;
        @media (max-width: 991.98px) {
            flex-direction: column;
            align-items: center;
        }
    }
    &__user-info,
    &__user-access {
        width: 450px;
        @media (max-width: 479.98px) {
            width: 100%;
        }
    }
}

.form-user-info,
.form-access {
    &__line {
        border-bottom: none;        
    }
    &__input {
        border-bottom: 2px solid #d9d9d9a4;
    }
}
.form-access {
    &__body {
        display: none;
        &._visible {
            display: flex;
            flex-direction: column;
        }
    }
    &__button {
        margin: 0 0 30px 0;
        &._disabled {
            display: none;
        }
    }
}
.button_black {        
    border: 2px solid #252525;
    &:hover {
        background-color: #252525;
        color: #fff; 
    }
}

.edit-data-btn {
    display: block;
    &._hidden {
        display: none;
    }
}

.change-data-btn {
    display: none;
    &._visible {
        display: block;
        &:hover {
            background-color: $decorDarkColor;
        }
    }
}

.block-main {
    padding: 20px 0 0 0;
    &__container {
        height: auto;
    }
    &__body {    
        margin: 0 0 20px 0;
    }
    &__create {
        width: 200px;
        padding: 15px 20px;
        border-radius: 20px;
        border: 2px solid #252525;
        color: #252525;
        font-weight: 600;
        margin-bottom: 20px;
        transition: all 0.6s ease 0s;
        &:hover {
            color: #fff;
            background-color: #252525;
        }
    }
    &__swiper {
        display: flex;
        overflow: hidden;    
    }
}

.slide-main-block {  
    &__actions {
        position: absolute;
        top: 20px;
        left: 20px;
        display: flex;
        align-items: center;
        gap: 20px;
        z-index: 2;
        background-color: #25252563;
        border-radius: 20px;
        padding: 20px;
        &_hidden {
            display: none;
        }
        svg {
            fill: #fff;
            font-size: 1.2rem;
            cursor: pointer; 
            transition: transform 0.6s ease 0s;       
            &:hover {
                transform: scale(1.2);
            }
        }

    }
    &__image {
        display: block;
        max-width: 1200px;
        height: 390px;
        position: relative;
        @media (max-width: 991.98px) {
            max-width: 992px;
            height: 300px;
        }
        @media (max-width: 767.98px) {
            max-width: 768px;
            height: 220px;
        }
        @media (max-width: 579.98px) {
            max-width: 580px;
            height: 180px;
        }
        @media (max-width: 479.98px) {
            max-width: 480px;
            height: 140px;
        }
        @media (max-width: 371.98px) {
            max-width: 372px;
            height: 110px;
        }
        @media (max-width: 319.98px) {
            max-width: 320px;
            height: 100px;
        }
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }
}

.block-companies {
    &__container {
        height: auto;
    }
    &__body {
        padding: 40px 0;  
        @media (max-width: 767.98px) {
            padding: 20px 0;
        }
        @media (max-width: 479.98px) {
            padding: 10px 0;
        }
    }
    &__create {
        padding: 15px 20px;
        border-radius: 20px;
        border: 2px solid #252525;
        font-weight: 600;
        color: #252525;
        background-color: #fff;
        margin-bottom: 20px;
        box-shadow: 3px 3px 3px #25252525;
        outline: none;
        transition: all 0.6s ease 0s;
        &:hover {
            color: #fff;
            background-color: #252525;
        }
    }
    &__swiper {
        display: flex;
        overflow: hidden;
    }
}

.slide-companies-block {
    &__content {
        display: flex;        
        justify-content: space-around;
        align-items: center;         
        gap: 20px;
    }

    &__image {
        display: block;
        width: 100%;
        height: 180px;
        position: relative;
        cursor: pointer;
        background-color: #fff;
        @media (max-width: 991.98px) {
            height: 150px;
        }
        @media (max-width: 579.98px) {
            height: 100px;
        }
        @media (max-width: 479.98px) {
            height: 80px;
        }
        @media (max-width: 379.98px) {
            height: 50px;
        }
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: contain;
        }
    }
    &__actions {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        gap: 20px;
        z-index: 2;
        background-color: #25252563;
        border-radius: 20px;
        padding: 10px;
        &_hidden {
            display: none;
        }
        svg {
            fill: #fff;
            cursor: pointer;
            @media (max-width: 479.98px) {
                font-size: 0.8rem;
            }
        }
    }
}

.block-best,
.block-new {
    background-color: #fff;
    &__content {
        padding: 60px 0;
        @media (max-width: 991.98px) {
            padding: 30px 0;
        }
    }
    &__title {
        font-family: "Raleway";
        font-size: 30px;
        font-weight: 700;
        &:not(:last-child) {
            margin-bottom: 40px;
        }
        @media (max-width: 479.98px) {
            text-align: center;
            font-size: 22px;
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }
        @media (max-width: 371.98px) {
            font-size: 18px;
        }
    }
    &__item {
        cursor: pointer;
    }
}

.more-best-block,
.more-new-block {
        display: flex;    
        justify-content: flex-end;
        align-items: center;
    &__link {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        color: #AD902B;  
        position: relative; 
        gap: 10px;
        &:hover {
            text-decoration: underline;
        }
        @media(max-width: 479.98px) {
            font-size: 16px;
        }
    }
    svg {
        width: 10px;
        fill: #AD902B;
    }
}

.catalog {
    &__content {
        display: flex;
        flex-direction: column;
        padding: 60px 0 80px 0;
        @media(max-width: 991.98px) {
            padding: 40px 0;
        }
    }
    &__items {
        display: grid;
        justify-content: space-around;
        grid-template-columns: repeat(3, minmax(300px, 360px));
        column-gap: 30px;
        row-gap: 40px;
        @media (max-width: 991.98px) {
            grid-template-columns: repeat(3, minmax(220px, 280px));
            gap: 20px;
        }
        @media (max-width: 767.98px) {
            grid-template-columns: repeat(2, minmax(250px, 330px));
        }
        @media (max-width: 579.98px) {
            grid-template-columns: repeat(2, minmax(210px, 250px));
        }
        @media (max-width: 479.98px) {
            grid-template-columns: repeat(1, minmax(280px, 360px));
            gap: 30px;
        }
        @media (max-width: 319.98px) {
            grid-template-columns: repeat(1, 250px);
        }
    }
}

.item-catalog {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;   
    height: 300px;
    background-color: #ffffff;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    border: 1px solid #d9d9d94a;
    box-shadow: 5px 5px 10px #d9d9d954;
    &__actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: #252525b2;
        padding: 20px;
        border-radius: 20px;
        gap: 20px;
        svg {
            cursor: pointer;
            fill: #fff;
            font-size: 1.2rem;
            transition: all 0.3s ease 0s;
            &:hover {
                transform: scale(1.2);
            }
        }
        @media(max-width: 991.98px) {
            padding: 15px;
        }
        &_hidden {
            display: none;
        }
    }
    &_hidden {
        display: none;
    }
    @media(max-width: 991.98px) {
        height: 220px;
    }
    @media(max-width: 767.98px) {
        height: 240px;
    }
    @media(max-width: 579.98px) {
        height: 200px;
    }
    @media(max-width: 479.98px) {
        height: 300px;
    }
    @media (max-width: 319.98px) {
        height: 200px;
    }
    &__image {
        position: relative;
        width: 360px;
        height: 300px;
        @media(max-width: 991.98px) {
            width: 280px;
            height: 220px;
        }
        @media(max-width: 767.98px) {
            width: 330px;
            height: 240px;
        }
        @media(max-width: 579.98px) {
            width: 250px;
            height: 200px;
        }
        @media(max-width: 479.98px) {
            width: 360px;
            height: 300px;
        }
        @media (max-width: 319.98px) {
            width: 250px;
            height: 200px;
        }
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover; 
            transition: transform 0.8s ease 0s;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
    &__title {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100px;
        bottom: -40px;
        left: 0;
        font-size: 16px;
        letter-spacing: 0.6px;
        font-weight: 500;
        color: #fff;
        line-height: 125%;
        text-align: center;
        text-transform: uppercase;
        justify-content: space-around;
        align-items: center;
        background-color: #000000a6;
        padding: 20px 15px 60px 15px;
        transition: bottom 0.8s ease 0s;
        &:hover {
            bottom: 0;
        }
        @media (max-width: 991.98px) {
            font-size: 14px;
        }
        @media (max-width: 579.98px) {
            padding: 10px 10px 50px 10px;
        }
    }
    &__add {        
        svg {
            fill: #b4b4b4;
            font-size: 7.5rem;
            cursor: pointer;
            transition: all 0.6s ease 0s; 
            &:hover {
                transform: rotate(-90deg) scale(1.2);
                fill: #1560BD;
            }         
        }

    }
    &__category {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: auto;
        height: auto;
        padding: 5px 15px;
        border-radius: 0 10px 0 10px;
        background-color: $decorDarkColor;
        color: #fff;
        font-size: 14px;
    }
    &__name {
        &_hidden {
            display: none;
        }
    }
}

.product-main {
    &__content {
        padding: 40px 0 60px 0;
        display: flex;
        flex-direction: column;
    }
    &__categories {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 0 20px 0;
        gap: 20px;
        margin-bottom: 20px;
        @media (max-width: 991.98px) {
            flex-wrap: nowrap;
            overflow: auto;
        }
        li.active {
            button {
                background-color: $decorDarkColor;
                color: $whiteColor;
            }
        }
    }
    &__type {
        font-size: 24px;
        font-weight: 600;
        color: #252525;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        margin-bottom: 30px;
        padding-left: 5px;
        @media(max-width: 767.98px) {
            font-size: 20px;
        }
        @media(max-width: 479.98px) {
            font-size: 18px;
        }
        @media(max-width: 379.98px) {
            font-size: 16px;
        }
    }
    &__banner {
        max-width: 1400px;
        height: 400px;
        background: url('../../src/assets/img/banner.JPG') center / cover no-repeat;  
        margin: 0 0 60px 0; 
        @media(max-width: 991.98px) {
            height: 350px;
        }
        @media(max-width: 767.98px) {
            height: 300px;
            background: url('../../src/assets/img/banner2.jpg') 85% 0 / cover no-repeat;
        }         
    }
    &__items {
        display: grid;
        grid-template-columns: repeat(4, minmax(220px, 300px));
        justify-content: space-around;
        gap: 25px;
        &:not(:last-child) {
            margin-bottom: 40px;
        }
        @media (max-width: 991.98px) {
            grid-template-columns: repeat(3, minmax(200px, 280px));
        }
        @media (max-width: 767.98px) {
            grid-template-columns: repeat(2, minmax(200px, 300px));
        }
        @media (max-width: 579.98px) {
            gap: 20px;
        }
        @media (max-width: 479.98px) {
            grid-template-columns: repeat(2, minmax(150px, 250px));
            gap: 10px;
        }
        @media (max-width: 379.98px) {
            grid-template-columns: repeat(2, minmax(125px, 200px));
        }
    }
}

.skeletons {
    display: grid;
    justify-self: center;
}

.sort-product {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
    &__body {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        font-size: 16px;
        font-weight: 500;
        gap: 10px;
        span {
            color: #AD902B;
            text-decoration: underline;
        }
    }
}

.popup-sort {
    position: relative;
    &__label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 8px;
        color: #AD902B;  
        cursor: pointer;
        svg {
            width: 14px;
            fill: #AD902B;
        }     
    }
}

.list-popup-sort {
    position: absolute;
    top: 20px;
    right: -10px;
    display: flex;
    flex-direction: column;
    width: 220px;
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    border-left: 1px solid #d9d9d92b;
    border-right: 1px solid #d9d9d92b;
    border-bottom: 1px solid #d9d9d92b;
    box-shadow: 10px 10px 10px #d9d9d92b;
    padding: 10px 0;
    z-index: 5;
    overflow: hidden;
    li {
        text-align: right;
        padding: 8px 32px 8px 20px;
        cursor: pointer;
        &:hover {
            color: #AD902B;
            background-color: #f7f7f77f;
        }
    }
}


.item-product {  
    position: relative;         
    background-color: #ffffff;
    border-radius: 20px;         
    overflow: hidden;         
    height: 100%; 
    border-left: 2px solid #f7f6f687;
    border-top: 2px solid #f7f6f687;
    box-shadow: 5px 5px 5px #d9d9d933;
    &__top-product {
        position: absolute;
        top: 0;
        right: 0;
        align-self: flex-end;
        background-color: #b45a40;
        color: #ffffff;
        padding: 10px;
        font-size: 16px;
        letter-spacing: 0.5px;
        border-radius: 0 0 0 20px;
        z-index: 2;
        &-faded {
            background-color: #636363;
        }
        @media(max-width: 767.98px) {
            font-size: 14px;
        }
        @media(max-width: 319.98px) {
            font-size: 10px;
        }
    }
    &__body {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    &__add {   
        display: flex;
        align-items: center;    
        height: 100%;        
        justify-content: center;            
        padding: 60px 0;
        svg {
            fill: #b4b4b4;
            font-size: 7.5rem;
            cursor: pointer;
            transition: all 0.6s ease 0s;

            &:hover {
                transform: rotate(-90deg) scale(1.2);
                fill: $decorDarkColor;
            }
        }
    }
    &__actions {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: #2525255d;
        padding: 20px;
        border-radius: 20px;
        gap: 20px;
        svg {
            cursor: pointer;
            fill: #fff;
            font-size: 1.2rem;
            transition: all 0.3s ease 0s;
            &:hover {
                transform: scale(1.2);
            }
        }
        &_hidden {
            display: none;
        }
        @media (max-width: 991.98px) {
            padding: 15px;
        }
    }
    &__image {
        position: relative;
        width: 100%;
        height: 220px;
        overflow: hidden;
        cursor: pointer;
        @media (max-width: 579.98px) {
            height: 200px;
        }
        @media (max-width: 479.98px) {
           height: 160px;
        }
        @media (max-width: 379.98px) {
            height: 130px;
        }
        @media (max-width: 319.98px) {
            height: 100px;
        }
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;    
            transition: transform 0.8s ease 0s;   
            &:hover {
                transform: scale(1.1);
            }
        }
        .faded {
            opacity: 50%;
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding: 10px 15px;
        &-faded {
            opacity: 60%;
        }
        @media (max-width: 379.98px) {
            padding: 8px;
        }
    }
    &__top {
        display: flex;
        flex: 1 0 140px;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 8px;
        @media(max-width: 479.98px) {
            flex: 1 0 120px;
            gap: 5px;
        }
    }
    &__brand {
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;
        color: #484848;
        @media(max-width: 371.98px) {
            font-size: 10px;
        }
    }
    &__bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 10px;
        @media(max-width: 479.98px) {
            gap: 7px;
        }
        @media(max-width: 379.98px) {
            gap: 5px;
        }
    }
    &__available {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        color: $decorDarkColor;
        @media(max-width: 479.98px) {
            font-size: 14px;
        }
        @media(max-width: 379.98px) {
            font-size: 12px;
        }
        &_no {
            white-space: nowrap;
            color: #7f766acb;
        }
    }
    &__sizes {
        display: flex;
        flex-direction: column;
        font-size: 10px;
        font-weight: 500;
        gap: 5px;    
        padding: 10px 0;
    }
    &__thickness,
    &__details {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
    }
    &__value {    
        color: #AD902B;
    }
    &__titles {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        cursor: pointer;
    }
    &__title {
        font-size: 14px;
        font-weight: 700;
        color: #AD902B;
        @media(max-width: 479.98px) {
            font-size: 12px;
        }
        @media(max-width: 379.98px) {
            font-size: 11px;            
        }
    }
    &__price-block {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        gap: 10px;
        @media(max-width: 479.98px) {
            gap: 5px;
        }
    }
    &__price-row {
        display: flex;
        align-items: center;
        gap: 15px;
        @media(max-width: 479.98px) {
            gap: 10px;
        }
    }
    &__price {
        font-size: 22px;
        font-weight: 600;
        white-space: nowrap;
        color: #252525;
        span {
            font-size: 14px;
            margin-right: 10px;
            @media(max-width: 479.98px) {
                font-size: 12px;
                margin-right: 8px;
            }
            @media(max-width: 379.98px) {
                font-size: 10px;
                margin-right: 5px;
            }
        }
        @media(max-width: 479.98px) {
            font-size: 18px;
        }
        @media(max-width: 379.98px) {
            font-size: 15px;
        }
        &_strike {
            font-size: 20px;
            color: #252525b8;
            text-decoration: line-through;
            @media(max-width: 479.98px) {
                font-size: 16px;
            }
        
            @media(max-width: 379.98px) {
                font-size: 14px;
            }
        }
        &_red {
            color: #b45a40;
        }
        &_percents {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.5px;
            color: #fff;
            background-color: #e53638;
            padding: 5px 8px;
            border-radius: 8px;
            &-black {
                background-color: #252525;
            }
            @media(max-width: 479.98px) {
                font-size: 12px;
            }
        
            @media(max-width: 379.98px) {
                font-size: 10px;
            }
        }
    }
    &_hidden {
        display: none;
    }
}

.sizes_hidden {
    display: none;
}

.skeleton {
    width: 240px;
    border-radius: 20px;
    overflow: hidden;
}

.item-rating {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    font-weight: 500;
    color: #7f766a;
    @media(max-width: 479.98px) {
        font-size: 12px;
        gap: 5px;
    }
    .empty-star {
        fill: #e8c54554;
        font-size: 14px;
    }
    .rating-star {
        fill: #e8c445;
        font-size: 14px;
    }
}

.label-bold {
    white-space: nowrap;
    color: #484848;
}

.product-pagination {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    overflow: hidden;
    .next,
    .previous,
    &__page {        
        cursor: pointer;
        text-align: center;
        border-radius: 50%;
        border: 2px solid $decorColor;
        transition: all 0.3s ease 0s;
        &:hover {
            color: #fff;
            border: 2px solid $decorDarkColor;
            background-color: $decorDarkColor;
            svg {
                fill: #fff;
            }
        }         
        a {        
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            @media(max-width: 379.98px) {
                width: 30px;
                height: 30px;
            }
        }
    }
    &__page {        
        font-size: 16px;
        font-weight: 600;
        background-color: #ffffff;
        &.selected {
            color: #fff;
            border: 2px solid $decorDarkColor;
            background-color: $decorDarkColor;
        }        
    }
}


.next, 
.previous {
    background-color: $decorColor;
    svg {
        fill: $lightGreyColor;
        width: 10px;
    }
    &.disabled {
        display: none;
    }
}


.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 480px;
    svg {
        width: 80px;
        fill: #D9D9D9;
        animation: rotate 5s infinite linear;
        @keyframes rotate {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(360deg);
            }
        }        
    }
}
.product-card {
    &__content {
        display: flex;
        flex-direction: column;
        padding: 20px 0 60px 0;
        gap: 60px;
        @media (max-width: 991.98px) {
            padding: 20px 0;
        }
        &_shadow {
            background-color: #252525;
        }
    }
    &__error { 
        color: #AD902B;
        font-weight: 600;
        max-width: 350px;
        line-height: 1.2;
    }
    &__warning {
        color: #AD902B;
        font-weight: 600;
        line-height: 1.2;
    }
    &__aside {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 40px;   

        @media(max-width: 991.98px) {
            flex-direction: column;
            width: 440px;
            gap: 20px;
            padding-right: 10px;
            padding-right: 10px;
        }
    }
    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;
        @media(max-width: 991.98px) {
            flex-direction: row;
        }
        @media(max-width: 371.98px) {
            flex-wrap: wrap;
            row-gap: 20px;
            justify-content: flex-end;
        }

    }
    &__quantity {
        position: relative;
    }
    &__motion {
        display: none;
    }
    &__img-clone {
        position: relative;
        width: 60px;
        height: 60px;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__else {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        @media(max-width: 991.98px) {
            flex-direction: column;
            align-items: center;
        }
    }
    &__login-link {   
        font-weight: 500;
        text-align: center;
        line-height: 1.2;
        color: #252525;
        margin-bottom: 30px;
        span {
            color: #AD912B;
        }            
        &:hover {
            span {
                text-decoration: underline;                
            }
        } 
    }
}
.available {
    text-transform: uppercase;
    letter-spacing: 0.8px;
    font-weight: 600;
    line-height: 1.2;
    font-size: 18px;
    &_true {
        color: $decorDarkColor;
    }
    &_false {
        color: #b16451;
    }
}
.go-back {        
    align-self: flex-end;
    &__link {
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        color: #AD902B;
        font-size: 20px;
        font-weight: 500;
        transition: all 0.6s ease 0s;
        margin-left: 15px;
        &:hover {
            text-decoration: underline;
        }
        svg {
            width: 10px;
            fill: #AD902B;
        }            
    }
}


.images-product {
    display: flex;
    flex-direction: row-reverse;
    background-color: #fff;
    max-width: 480px;
    overflow: hidden;
    border: 1px solid #f7f6f6;
    border-radius: 20px;
    gap: 5px;
    box-shadow: 10px 10px 10px #d9d9d92b; 
    @media (max-width: 479.98px) {
        max-width: 360px;
        flex-direction: column;
    }
    &__slider {
        width: 120px;
        @media (max-width: 479.98px) {
            width: 100%;
            max-height: 140px;
        }
    }
    &__wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        gap: 40px;
        @media(max-width: 991.98px) {
            flex-wrap: wrap;
        }
    }
    &__swiper {
        height: 330px;
        @media (max-width: 579.98px) {
            height: 300px;
        }
        @media (max-width: 479.98px) {
            height: 120px;
        }
        @media (max-width: 371.98px) {
            height: 90px;
        }
        .swiper {
        height: 100%;
        }
    }
}

.slide-product {
    cursor: pointer;
    &__media {       
        display: flex; 
        width: 100%;
        position: relative;
        height: 110px;
        opacity: 0.7;
        transition: opacity 0.6s ease 0s;
        &:active, 
        &:hover {
            opacity: 1;
        }
        @media (max-width: 579.98px) {
            height: 100px;
        }
        @media (max-width: 479.98px) {
            height: 120px;
        }
        @media (max-width: 371.98px) {
            height: 90px;
        }
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }
}

.thumbs-images {
    width: 360px;
    @media (max-width: 579.98px) {
        width: 300px;
    }
    @media (max-width: 479.98px) {
        width: 360px;
    }
    @media (max-width: 379.98px) {
        width: 95vw;
    }
}
.slide-thumbs {  
         
    &__media {
        display: flex;
        position: relative;      
        width: 100%;
        height: 330px;
        cursor: pointer;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
        @media (max-width: 579.98px) {
            height: 300px;
        }
        @media (max-width: 371.98px) {
            height: 280px;
        }
    }
}

.info-product {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #484848;
    font-weight: 500;
    padding-bottom: 15px;
    max-width: 420px;
    &__titles {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 8px;        
        margin-bottom: 30px;
    }
    &__title, 
    &__subtitle {
        font-size: 22px;
        line-height: 1.2;
        color: #000;
        @media (max-width: 479.98px) {
            font-size: 18px;
        }
    }
    &__number,
    &__brand, 
    &__thick, 
    &__length,
    &__curl,
    &__volume {        
        display: flex;
        gap: 20px;
        justify-content: space-between;
        align-items: center;      
        flex-wrap: wrap;  
        &:not(:last-child) {
            margin-bottom: 20px;             
        }  
        label {
            font-size: 16px;
            font-weight: 600;
            span {
                color: #AD902B;
                margin-left: 10px;
            }
        }
    }

    &__thick,
    &__length,
    &__curl {
        flex-direction: column;
        align-items: flex-start;
    }

    &__price {   
        display: flex;     
        justify-content: space-between;      
        margin-right: 10px;
        margin-top: 30px;
        color: #252525;
        span {
            font-size: 20px;
            font-weight: 600;
        }
    }
}

.price__value {
    font-size: 24px;        
    font-weight: 600;        
    color: #000;        
    gap: 10px;
    @media(max-width: 479px) {
        font-size: 20px;
    }
    span {
        margin-right: 10px;
        font-size: 16px;
        @media(max-width: 479px) {
            font-size: 14px;
        }
    }
    &_red {
        color: #b45a40;
    }
    &-block {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
    }
    &-row {
        display: flex;
        flex-direction: row-reverse;
        gap: 10px;
        align-items: center;
    }
    &_percents {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: #fff;
        background-color: #b45a40;
        padding: 5px 8px;
        border-radius: 8px;
    }
    &_strike {
        font-size: 20px;
        text-decoration: line-through;
        color: #252525c7;
    }
}

.volume__value {
    border-radius: 20px;
    border: 2px dashed #D9D9D9;
    padding: 5px 8px;
    color: #AD902B;
}

.length,
.thick,
.curl {
    &__list {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 5px;
        li {
            cursor: pointer;
            border: 2px solid $mainColor;
            border-radius: 20px;
            padding: 5px 10px;
            text-align: center;
            font-size: 14px;
            transition: all 0.3s ease 0s;
            white-space: nowrap;
            &:hover {
                color: #fff;
                border: 2px solid $mainColor;
                background-color: $mainColor;
            } 
        }
    }
}

.lenghtItem,
.thickItem,
.curlItem {
    background-color: #fff;
}

.activeLength,
.activeThick,
.activeCurl {
    color: #fff;
    background-color: $mainColor;
}

.curl__select,
.thick__select,
.length__select {
    display: flex;
}

.titles-product {
    display: flex;
    gap: 20px;
    overflow: auto;
}

.description-product {
    display: flex;
    max-width: 700px;
    min-height: 200px;
    width: 100%;
    flex-direction: column;
    border-radius: 20px;
    padding: 40px 20px;
    background-color: #f7f6f677;
    border: 1px solid #f7f6f6;
    box-shadow: 10px 10px 10px #d9d9d92b;
    @media (max-width: 991.98px) {
        max-width: 600px;
        margin: 0 auto;
    }
    &__title {
        font-size: 20px;
        white-space: nowrap;
        font-weight: 500;
        position: relative;
        margin-bottom: 30px;
        cursor: pointer;
        color: #585858;
        &_active {
            color: #252525;
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                top: 34px;
                left: 0;
                background-color: #AD902B;
                @media(max-width: 579.98px) {
                    top: 28px;
                }
            }
        }
        @media(max-width: 579.98px) {
            font-size: 18px;
        }
    }
    &__text {
        font-size: 16px;
        line-height: 1.44;
        &:not(:last-child) {
            margin-bottom: 15px;
        }
        &_hidden {
            display: none;
        }
        @media(max-width: 579.98px) {
            font-size: 14px;
        }
    }
    &__pmu-link {
        color: #7f766a;
        transition: color 0.3s ease 0s;
    }
    &__pmu-link:hover {
        color: #AD902B;
    }
}

.reviews {
    &__items {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        @media(max-width: 991.98px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media(max-width: 767.98px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width: 479.98px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__text {
        color: #484848;
        text-align: center;
        line-height: 1.2;
        padding-bottom: 30px;
    }
}

.cart {        
    padding: 40px 0 60px 0;
    @media (max-width: 767.98px) {
        padding: 20px 0 40px 0;
    }
    &__content {
        display: flex;
        flex-direction: column;
        gap: 40px;

    }
}

.header-cart {
    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
    }
    &__title {
        display: flex;
        align-items: center;
        gap: 15px;
        font-size: 24px;
        font-weight: 500;
        color: #252525;
        svg {
            fill: #252525;
            width: 28px;
        }
        @media(max-width: 479.98px) {
            font-size: 20px;
        }
    }
    &__clear {
        display: flex;
        align-items: center;
        gap: 15px;
        font-size: 20px;
        font-weight: 500;
        color: #AD902B;
        svg {
            width: 18px;
            fill: #AD902B;
        }
        @media(max-width: 479.98px) {
            font-size: 14px;
            gap: 8px;
            svg {
                width: 16px;
            }
        }
    }
}

.body-cart {
    &__items {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}

.item-cart {
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        box-shadow: 5px 5px 5px #f7f6f6c0;
        border: 1px solid #f7f6f6;
        border-radius: 20px;
        overflow: hidden;
        padding-right: 20px;
        margin: 0 0 40px 0;
        @media (max-width: 649.98px) {
            flex-direction: column;
            position: relative;
            padding: 10px 15px 10px 0;
            margin: 0 0 30px 0;
        }
        @media (max-width: 379.98px) {
            flex-direction: column;
            padding: 10px 15px 10px 15px;
        }
    }
    &__product-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        overflow: hidden;
        &_faded {
            opacity: 60%;
        }
        @media (max-width: 649.98px) {
            width: 100%;
            justify-content: space-around;
        } 
        @media (max-width: 379.98px) {
            flex-direction: column;
            text-align: center;
            gap: 15px;
        }
    }
    &__image {
        position: relative;
        width: 200px;
        height: 180px;
        cursor: pointer;
        @media (max-width: 649.98px) {
            width: 150px;
            height: 134px;        
            border-radius: 20px;
            overflow: hidden;
            border: 1px solid #D9D9D9;
        }
        @media (max-width: 419.98px) {
            width: 120px;
            height: 104px;
        }
        @media (max-width: 379.98px) {
            width: 200px;
            height: 180px;
        }
        @media (max-width: 319.98px) {
            width: 180px;
            height: 160px;
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }  
    }
    &__info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 14px;
        line-height: 1.4;
        h4, 
        span {
            font-weight: 500;
        }
        @media (max-width: 767.98px) {
            font-size: 12px;
            padding-top: 10px;
        }
        @media (max-width: 419.98px) {
            gap: 5px;
        }
        @media (max-width: 379.98px) {
            align-items: center;
        }
    }
    &__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;   
        @media (max-width: 991.98px) {
            flex-direction: column-reverse;
            gap: 20px;
        }
        @media (max-width: 649.98px) {
            flex-direction: row;
            width: 100%;
            justify-content: space-around;
            margin-left: -25px;
        }
        @media (max-width: 419.98px) {
            flex-direction: row-reverse;
            margin: 0;
        }
        @media (max-width: 379.98px) {
            flex-wrap: wrap-reverse;
        }
    }
    &__unavailable {
        display: flex;
        text-transform: uppercase;
        color: #b45a40;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.2;
        @media(max-width: 991.98px) {
            font-size: 18px;
        }
        @media(max-width: 767.98px) {
            font-size: 16px;
        }
        @media(max-width: 479.98px) {
            font-size: 14px;
        }
    }
    &__price {
        width: 90px;
        font-size: 26px;
        font-weight: 500;
        white-space: nowrap;
        display: flex;
        justify-content: center;
        @media (max-width: 479.98px) {
            font-size: 22px;
        }
    }
    &__delete {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px dashed #d7d7d7;
        transform: rotate(-45deg);
        text-align: center;
        align-self: flex-end;
        font-size: 24px;
        font-weight: 500;
        transition: all 0.3s ease 0s;
        &:hover {
            background-color: #eeecec85;
        }
        @media (max-width: 991.98px) {
            width: 32px;
            height: 32px;
        }
        @media (max-width: 649.98px) {
            border: none;
            position: absolute;
            top: 3px;
            right: 5px;       
            font-size: 28px;
            background-color: inherit;
            &:hover {
                background-color: inherit;
            }
        }
    }
}

.info-cart {
    &__titles {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        @media (max-width: 319.98px) {
            flex-direction: column;
        }
    }
    &__title{
        color: #AD902B;
        width: 250px;
        @media (max-width: 767.98px) {
            width: 150px;            
        }
        @media (max-width: 379.98px) {
            width: 220px;
        }
    }
    &__line {
        display: flex;
        gap: 10px;   
        @media (max-width: 419.98px) {
            gap: 5px;
        }
    }
}

.quantity {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;     
    width: 140px;   
    background-color: #f7f6f682;
    border-radius: 20px;
    &__product-added {
        position: absolute;
        width: 330px;
        top: -115px;
        right: -190px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 0.5px;
        color: #fff;
        text-align: center;
        background-color: #252525;
        border-radius: 5px;
        padding: 10px 8px;
        opacity: 100;
        transition: all 0.6s ease-in 0s;
        &_hidden {
            opacity: 0;
        }
        @media(max-width: 991.98px) {
            top: -105px;
            right: -160px;
        }
        @media(max-width: 479.98px) {
            top: -55px;
            right: -99%;
            font-size: 14px;
            max-width: 280px;
            padding: 8px 5px;
        }
        @media(max-width: 359.98px) {
            top: -50px;
            max-width: 250px;
            font-size: 12px;
            right: 0;
        }
    }
    &__minus,
    &__plus {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $decorColor;        
        text-align: center;
        font-size: 26px;
        transition: all 0.3s ease 0s;
        color: $lightGreyColor;
        &:hover {
            background-color: $decorDarkColor;
            color: #fff;
        }
    }
    &__text {
        font-size: 18px;
        font-weight: 600;
    }

}

.bottom-cart {
    &__actions {
        display: flex;
        justify-content: space-around;
        gap: 20px;
        @media (max-width: 479.98px) {
            flex-direction: column-reverse;
            gap: 30px;
        }
    }
}


.popup-cart {
    display: flex; 
    &__content {
        display: grid;
        grid-template-columns: repeat(1, 55%) repeat(1, 45%);
        width: 100%;
        height: 100%;
        @media(max-width: 991.98px) {
            grid-template-columns: 100%;
        }
    }
    &__body,
    &__aside {
        padding: 40px 50px 80px;
        @media(max-width: 767.98px) {
            padding: 40px 20px;
        }
    }
    &__body {
        display: flex;
        background-color: #fff;            
        justify-self: flex-end;
        min-height: 100vh;
        @media(max-width: 991.98px) {
            justify-self: center;
            order: 2;
        }
    }
    &__redirect {
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 40px;
        max-width: 500px;
    }
    &__text {
        display: none;
        flex-direction: column;
        margin: 40px auto;
        text-align: center;
        min-height: 100vh;        
        gap: 20px;
        padding: 0 15px;
        svg {
            width: 200px;
            height: 200px;
            fill:#b45a40;
            margin: 15px auto;
        }
    }
    &__paragraph {
        color: #252525;
        font-weight: 500;
        line-height: 1.2;
        font-size: 16px;

    }
    &__items {
        margin: 30px 0;
    }
    &__item:hover {
        color: #AD902B;
    }
    &__aside {
        background-color: #f0f0f067;
    }
}

.redirect-cart {
    &__link {
        padding: 15px;
        background-color: #0a55b0;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 1px;
        border-radius: 20px;
        width: 200px;
        text-align: center;
        align-self: center;
        box-shadow: 2px 2px 5px #25252521;
        &:hover {
            background-color: #1560BD;
        }
        &_black {
            background-color: #252525;
            &:hover {
                background-color: #313131;
            }
        }
    }
    &__text {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.4;
        letter-spacing: 0.5px;
        &_gold {
            color: #AD902B;            
        }
        &_blue {
            font-size: 56px;
            color: #1560BD;
            background-color: #fff;
            border-radius: 50%;
            border: 6px dotted #1561bd71;
            width: 150px;
            height: 150px;
            margin: 0 auto;
            padding: 32px 0;
        }
        &_link {
            cursor: pointer;
            color: #1560BD;
            &:hover {
                color: #252525;
            }
        }
    }
}

.aside-popup-cart {  
    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 420px;
        @media (max-width: 991.98px) {
            margin: 0 auto;          
        }
    }
    &__items {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 30px;
        max-width: 96%;
        @media (max-width: 991.98px) {
            max-width: 100%;
        }
    }
    &__discount-persent,
    &__discount-sum {
        @media(max-width: 479.98px) {
            font-size: 14px;
        }
    }
    &__line {
        display: flex;
        max-width: 95.8%;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        @media (max-width: 991.98px) {
            max-width: 100%;
        }
        &_label {
            background-color: #fff;
            color: #AD902B;
            padding: 10px;
            font-weight: 500;
        }
        &_promo {
            justify-content: center;
            margin-bottom: 10px;
            gap: 2px;
            max-width: 96%;
            @media(max-width: 991.98px) {
                flex-direction: row;
                gap: 2px;
                max-width: 100%;
            }
            @media(max-width: 319.98px) {
                flex-direction: column;
                gap: 10px;
            }
        }
        &_msg {
            justify-content: center;
            text-align: center;
        }
        &_msg {
            background-color: #ffffff58;
            border-radius: 2px;
            border: 2px solid #fff;
        }
        &_overflow {
            max-width: 100%;
            margin-left: -9px;                
            margin-right: -9px;
            @media(max-width: 991.98px) {
                max-width: 105%;
            }
            @media(max-width: 479.98px) {
                max-width: 110%;
            }
        }
    }
    &__used-promocode {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        background-color: #84848425;
        color: #252525;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        border-radius: 2px;
        padding: 10px;
        @media(max-width: 479.98px) {
            font-size: 14px;
        }

        svg {
            fill: #252525c0;
            transform: rotate(90deg);
            width: 20px;
            height: 20px;
            @media(max-width: 479.98px) {
                width: 14px;
                height: 14px;
            }
        }
    }
    &__text-group {
        display: flex;
        flex-direction: column;
        gap: 5px;
        background-color: #ffffff58;
        border-radius: 2px;
        border: 2px solid #fff;
        color: #484848;
        font-size: 16px;
        letter-spacing: 0.5px;
        padding: 10px;
        width: 100%;
        @media(max-width: 479.98px) {
            font-size: 14px;
        }
    }
    &__text {
        display: flex;
        gap: 10px;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 1.2;
        color: #252525;
        svg {
            width: 30px;
            height: 30px;
        }
        &-delivery {
            display: flex;
            gap: 5px;
            svg {
                fill: #252525;
                width: 14px;
                height: 14px;
                cursor: pointer;
                transition: all 0.6s ease 0s;
                &:hover {
                    fill: #AD902B;
                    transform: rotate(-360deg);
                }
            }
        }
        @media(max-width: 479.98px) {
            font-size: 14px;
        }
        &_msg {
            font-size: 14px;
            color: #20a120;
            padding: 10px;   
            svg {
                fill: #20a120;
                flex: 0 0 30px;
                height: 30px;
                @media(max-width: 479.98px) {
                    flex: 0 0 24px;
                    height: 24px;
                }
            }
        }
        &_msg-red {
            color: #e53638;
            svg {
                fill: #e53638;
            }
        }
        &-total {
            margin: 10px 0 0 0;
            font-size: 20px;
            font-weight: 600;
            @media(max-width: 479.98px) {
                font-size: 18px;
            }
        }
    }
    &__input {
        width: 80%;
        padding: 10px;
        border: 2px dashed #7f766a71;    
        border-radius: 20px 0 0 20px;    
        font-size: 20px;
        line-height: 1.3;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-align: center;
        color: #252525;
        outline: none;
        box-shadow: 5px 5px 5px #2525250a;
        &::placeholder {
            text-transform: none;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0.5px;
        }
        @media(max-width: 379.98px) {
            font-size: 15px;
            padding: 10.9px 8px;
        }
        @media(max-width: 319.98px) {
            width: 100%;
            border-radius: 2px 2px 2px 2px;
        }
    }
    &__btn {
        padding: 14.9px 20px;
        border-radius: 0 20px 20px 0;
        background-color: #252525;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        box-shadow: 5px 5px 5px #2525250a;
        transition: background-color 0.4s ease 0s;
        &:hover {
            background-color: #323232;
        }
        &_disabled {
            cursor: default;
            background-color: #6b6b6b;
            color: #ebebebd1;
            &:hover {
                background-color: #6b6b6b;
            }
        }
        @media(max-width: 767.98px) {
            width: 120px;
            border-radius: 0 20px 20px 0
        }
        @media(max-width: 379.98px) {
            font-size: 13px;
            padding: 14.9px 8px;
        }
        @media(max-width: 319.98px) {
            width: 100%;
            border-radius: 2px 2px 2px 2px;
        }
    }
}

.item-aside-popup {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    &__image {
        position: relative;
        width: 60px;
        height: 60px;
        @media(max-width: 479.98px) {
            flex: 0 0 40px;
            height: 40px;
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;        
            border-radius: 5px;
        }
        span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: -15%;
            right: -15%;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #00000094;
            color: #fff;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            @media(max-width: 479.98px) {
                width: 20px;
                height: 20px;
            }
        }
    }
    &__info {
        display: flex;
        flex-direction: column;
        gap: 5px;               
        width: 100%;                   
        &-top,
        &-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;  
        }
    }
    &__name,
    &__price,
    &__promo-price,
    &__used-promocode {
        font-weight: 500;
        line-height: 1.2;
    }

    &__name {
        max-width: 300px;
        font-size: 14px;
        color: #252525;
        @media(max-width: 479.98px) {
            font-size: 12px;
        }
    }

    &__used-promocode {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 12px;
        color: #484848c4;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        @media(max-width: 479.98px) {
            font-size: 10px;
        }
        svg {
            fill: #48484884;
            width: 14px;
            height: 14px;
            transform: rotate(90deg);
            @media(max-width: 479.98px) {
                width: 12px;
                height: 12px;
            }
        }
    }

    &__price,
    &__promo-price {
        font-size: 15px;
        color: #000;
        white-space: nowrap;
        @media(max-width: 479.98px) {
                font-size: 13px;
            }
        &_strike {
            white-space: nowrap;
            font-weight: 500;
            line-height: 1.2;
            text-decoration: line-through;
            font-size: 13px;
            color: #484848cb;
            @media(max-width: 479.98px) {
                font-size: 11px;
            }
        }
    }
}

.text-group {
    &__label {
        color: #252525;
        font-weight: 500;
    }
}

.delete-icon {
    cursor: pointer;
    transition: fill 0.6s ease 0s;
    &:hover {
        fill: #252525;
    }
}

.popup-form {
    display: flex;
    flex-direction: column;
    &__text {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 0.5px;
        text-align: left;
        color: #AD902B;
        margin-bottom: 20px;
        @media(max-width: 767.98px) {
            text-align: center;
        }
    }
    &__search-line {
        position: relative;
    }
    &__search {
        width: 100%;
        font-size: 15px;
        font-weight: 500;
        padding: 8px 10px 8px 40px;
        letter-spacing: 0.5px;
        outline: none;
        &::placeholder {
            color: #848484;        
        }
        &_svg {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 18px;
            height: 18px;
            fill: #25252594;
        }
    }
    &__line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;    
        @media(max-width: 767.98px) {
            gap: 10px;
        }
        &:not(:last-child) {
            margin-bottom: 15px;
        }
        @media(max-width: 579.98px) {
            flex-direction: column;
            align-items: flex-start;
        }
        &_select {
            position: relative;
            user-select: none;
        }
    }
    &__arrow{
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 2;
        cursor: pointer;
        transition: fill 0.4s ease 0s;

        &:hover {
            fill: #AD912B;
        }

        @media(max-width: 579.98px) {
            right: 10px;
            top: 34px;
        }
    }
    &_rotate {
        transform: rotate(-180deg);
    }
    &_hidden {
        display: none;
    }
    .popup-line__textarea {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }
    &__label {
        font-size: 16px;
        font-weight: 600;
        span {
            color: #AD902B;
        }
    }
    &__input {
        width: 270px;
        height: 33px;
        @media(max-width: 579.98px) {
            width: 100%;
        }
        @media(max-width: 579.98px) {
            width: 100%;
        }
        &_select {
            cursor: pointer;
            z-index: 2;
        }
    }
    &__wrapper {
        position: absolute;
        top: 18px;
        right: 0;        
        width: 270px;
        height: 250px;
        display: flex;
        flex-direction: column;
        overflow: hidden;   
        border-radius: 0 0 20px 20px;
        border: 2px solid #D9D9D9;
        @media(max-width: 579.98px) {
            width: 100%;
            left: 0;
            top: 40px;
        }
        &_hidden {
            display: none;
        }
    }
    &__list {        
        overflow: auto;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        background-color: #fafafa;
        border-radius: 0 0 20px 20px;
        color: #484848;
        z-index: 1;
        &_hidden {
            display: none;
        }
    }
    &__item {
        font-size: 14px;
        font-weight: 500;
        padding: 8px 10px;
        transition: all 0.4s ease 0s;
        cursor: pointer;
    
        &:hover {
            background-color: #9c9c9c0d;
            color: #AD912B;
        }
    }
    &__select {
        position: relative;
        user-select: none;
    
        svg {
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 2;
            cursor: pointer;
            transition: fill 0.4s ease 0s;
    
            &:hover {
                fill: #AD912B;
            }
    
            @media(max-width: 479.98px) {
                left: 170px;
                top: 30px;
            }
    
            @media(max-width: 379.98px) {
                left: 150px;
                top: 26px;
            }
        }
    }
    &__textarea {
        outline: none;
        resize: none;
        overflow: auto;        
        width: 100%; 
    }
    &__input,
    &__textarea {
        border: 2px solid #7f766a37;
        border-radius: 20px;
        padding: 10px; 
        outline: none;  
        &:focus {
            border: 2px solid #484848;
        }                
    }
    &__button {
        letter-spacing: 1px;
    }
}


.total {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 50px;    
    @media (max-width: 579.98px) {
        flex-direction: column;
        align-items: center;
    }
    &__number,    
    &__sum {
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: 22px;
        font-weight: 500;  
        @media (max-width: 991.98px) {
            font-size: 20px;
        }
        @media (max-width: 767.98px) {
            font-size: 18px;
        }
        @media (max-width: 379.98px) {
            font-size: 16px;
        }
    }
}

.go-shopping,
.checkout {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 600;
    text-transform: uppercase;
    padding: 15px;  
    border-radius: 20px;
    transition: all 0.8s ease 0s;
    box-shadow: 2px 3px 3px #d9d9d95f;
    svg {
        fill: #252525;
        transition: all 0.8s ease 0s;
    }
    @media(max-width: 579.98px) {
        font-size: 14px;
    }
}

.go-shopping {
    background-color: #ffffff;
    border: 2px solid #000;
    svg {
        width: 14px;
    }
    &:hover {
        background-color: #252525;
        color: #fff;
        svg {
            fill: #fff;
        }
    }
}
.checkout {
    background-color: $decorDarkColor;        
    border: 2px solid $decorDarkColor;
    color: $whiteColor;
    svg {
        fill: $whiteColor;
        width: 20px;
    }
    &:hover {
        svg {
            fill: $decorDarkColor;
        }
        color: $mainColor;
        background-color: $whiteColor;
    }
}


.success-main {
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 0;
        gap: 30px;
        @media (max-width: 991.98px) {
            padding: 30px 0;
        }
    }
    &__title {
        text-align: center;
        font-size: 20px;
        line-height: 1.33;
        font-weight: 600;
        @media (max-width: 479.98px) {
            font-size: 18px;
            max-width: 300px;
        }
    }
    &__subtitle {
        text-align: center;
        font-size: 16px;
        line-height: 1.33;
        font-weight: 600;
        color: #252525;
        @media (max-width: 479.98px) {
            font-size: 12px;
            max-width: 300px;
        }
    }
}

.body-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    &__svg {
        font-size: 180px;
        fill: $decorColor;
        margin-bottom: 30px;
    }
    &__block {
        display: flex;
        flex-direction: column;
        align-self: flex-start;        
        width: 100%;
        gap: 20px;
    }
    &__line {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        gap: 40px;
        font-size: 16px;
        svg {
            width: 16px;
            @media(max-width: 479.98px) {
                width: 14px;
            }
        }
        .rotate {
            transform: rotate(-180deg);
            fill: #AD902B;
        }
        &_cursor {
            color:#AD902B;
            svg {
                fill: #AD902B;
            }
            cursor: pointer;
        }
        @media (max-width: 479.98px) {
            font-size: 14px;
        }
        @media (max-width: 379.98px) {
            font-size: 12px;
        }
        &_bold {
            color: #000;
            font-weight: 600;
        }
    }
    &__details {
        text-align: right;
        line-height: 1.2;
        max-width: 250px;
    }
    &__image {
        position: relative;
        width: 400px;
        height: 200px;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
        @media (max-width: 479.98px) {
            width: 240px;
            height: 100px;
        }
    }
    &__address {
        max-width: 500px;
        font-size: 14px;
        line-height: 1.2;
        @media (max-width: 479.98px) {
            font-size: 12px;
            max-width: 300px;
        }
    }
    &__text {
        max-width: 500px;
        text-align: center;
        font-size: 16px;
        line-height: 1.44;
        @media (max-width: 479.98px) {
            font-size: 12px;
            max-width: 300px;
        }
        &_gold {
            font-size: 18px;
            font-weight: 600;
            color: #AD902B;
            @media (max-width: 479.98px) {
                font-size: 14px;
            }
        }
    }
    &__items {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        gap: 5px;
        @media(max-width: 479.98px) {
            font-size: 12px;
        }
        .bold {
            font-weight: 500;
            line-height: 1.2;
            white-space: wrap;
            @media(max-width: 479px) {
                max-width: 250px;                
            }
        } 

        &_hidden {
            display: none;
        }
    }
    &__button {
        width: 100%;
    }
}

.bottom-line {
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 2px solid #4848482b;
}

.bold {
    white-space: nowrap;
    font-weight: 500;
}

.link {
    cursor: pointer;
}

.footer {
    background-color: #f1ede7;
    padding-top: 60px;
}

.body-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;         
    padding-bottom: 60px;
    border-bottom: 2px solid #ffffff88;
    gap: 40px;
    @media (max-width: 767.98px) {
        flex-direction: column;
        align-items: center;
        gap: 80px;
    }
}

.contacts {
    &__title {
        font-family: "Raleway";
        font-size: 20px;
        line-height: 130%;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        &:not(:last-child) {
            margin-bottom: 30px;
        }    
        @media(max-width: 767.98px) {
            font-size: 18px;        
        }
        @media(max-width: 371.98px) {
            font-size: 16px;
        }
    }
    &__items {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}

.item-contacts__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    background-color: #ffffffd6;
    border: 2px solid $goldColor;
    box-shadow: 5px 5px 5px #48484818;
    font-size: 14px;
    line-height: 1.2;
    color: #000;
    border-radius: 20px;
    padding: 5px 10px;
    &:hover {
        color: $goldColor;
    }   
    @media(max-width: 371.98px) {
        font-size: 12px;
    }
    svg {
        width: 40px;
        height: 40px;
        fill: $footerDecor;
        transition: fill 0.3s ease 0s;
        &:hover {
            fill: #484848;
        }
    } 
    &_mail {
        svg {
            width: 38px;
            height: 40px;
        }
    }
    &_location {
        padding: 10px 15px 10px 7px;
    }
}

.link-contacts {
    &__items {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    &__item {
        span {
            white-space: nowrap;
        }
    }
}

.actions-footer {
    &__items {
        display: flex;
        flex-direction: column;
        @media(max-width: 767.98px) {
            font-size: 20px;
            text-align: center;
        }
    }
    &__item {
        max-width: 340px;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        @media(max-width: 991.98px) {
            max-width: 280px;
        }
    }
    &__link {
        font-family: "Raleway";
        font-size: 20px;
        color: #484848;
        line-height: 1.3;
        font-weight: 700;  
        letter-spacing: 0.8px;
        text-transform: uppercase;
        transition: color 0.3s ease 0s;
        &:hover {
            color: $goldColor;
        }   
        @media(max-width: 767.98px) {
            font-size: 18px;
        }  
        @media(max-width: 371.98px) {
            font-size: 16px;
        }
    }
}

.bottom-footer {
    display: flex;
    gap: 20px;        
    justify-content: center;
    align-items: center;
    &__text {
        font-size: 14px;
        font-weight: 500;
        padding: 40px 0;
        color: $footerDecor;
    }
}

.faq-main, 
.about-main, 
.payment-main,
.terms-main,
.privacy-main,
.returns-main,
.dispute-main {
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 60px 0 80px 0;
        @media (max-width: 991.98px) {
            padding: 40px 0 60px 0;
        }
    }
    &__title {
        max-width: 450px;
        font-size: 30px;
        line-height: 1.25;
        font-weight: 600;
        margin-bottom: 80px;
        text-align: center;
        @media (max-width: 991.98px) {
            margin-bottom: 40px;
            font-size: 24px;
        }
        @media (max-width: 767.98px) {
            font-size: 22px;
        }
        @media (max-width: 579.98px) {
            font-size: 20px;
        }
        @media (max-width: 379.98px) {
            font-size: 18px;
        }
    }
    &__text {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.3;
        color: #484848;
    }
}

.terms-main,
.privacy-main,
.returns-main,
.dispute-main {
    &__content {
        max-width: 90%;
        margin: 0 auto;
        @media(max-width: 379.98px){
            max-width: 95%;
        }        
    }
    &__text {
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin: 0 0 60px 0;
        p {
            letter-spacing: 0.5px;
        }
        @media(max-width: 379.98px) {
            font-size: 14px;
        }
    }
    &__link {
        font-weight: 700;
        transition: color 0.3s ease 0s;
        color: $decorColor;
        &:hover {
            color: $decorDarkColor;
        }
    }
    &__items {
        display: flex;
        flex-direction: column;
        gap: 60px;
        @media(max-width: 379.98px) {
            gap: 40px;
        }
    }
}

.dispute-main {
    min-height: 80vh;
    &__title {
        max-width: 720px;
    }
    &__item {
        text-align: center;
        line-height: 1.3;
    }
}

.returns-main {
    &__items {
        margin: 0 0 60px 0;
    }
}

.item-terms,
.item-returns,
.item-privacy {
    &__title {
        font-size: 22px;
        font-weight: 600;
        line-height: 1.2;
        margin: 0 0 20px 0;
        @media(max-width: 767.98px) {
            font-size: 20px;
        }
        @media(max-width: 379.98px) {
            font-size: 16px;
        }
    }
    &__text {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.3;
        letter-spacing: 0.5px;
        gap: 30px;
        @media(max-width: 379.98px) {
            font-size: 14px;
        }
    }
    &__list {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0 0 0 40px;
        @media(max-width: 767.98px) {
            padding: 0 0 0 20px;
        }
    }
    &__point {
        list-style: disc;    
        font-size: 17px;
        line-height: 1.2;
        color: #000;  
        max-width: 800px;
        @media(max-width: 767.98px) {
            font-size: 16px;
        }
        @media(max-width: 379.98px) {
            font-size: 14px;
        }
    }
    &__link {
        font-weight: 700;
        transition: color 0.3s ease 0s;
        color: $decorColor;

        &:hover {
            color: $decorDarkColor;
        }
    }
}

.item-returns {
    &__text {
        padding: 0 0 0 26px;
        @media(max-width: 379.98px) {
            padding: 0;
        }
    }
}

.item-privacy {
    &__subtitle {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: 0.5px;
        margin: 0 0 20px 0;
        @media(max-width: 767.98px) {
            font-size: 18px;
        }
        @media(max-width: 379.98px) {
            font-size: 15px;
        }
    }
    &__text {
        margin: 0 0 20px 0;
    }
    &__list {
        margin: 0 0 30px 0;
    }
    &__point {
        font-weight: 500;
    }
}

.payment-main {
    &__items {
        display: grid;
        align-items: flex-start;
        justify-content: space-between;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
        @media(max-width: 767.98px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width: 479.98px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 20px;
        @media (max-width: 767.98px) {
            gap: 10px;
        }
        svg {
            width: 100px;
            height: 100px;
            fill: #ccae41e1;
            transition: all 0.6s ease 0s;
            &:hover {
                transform: scale(1.1);
                fill: $goldColor;
            }
        }
    }
    &__title {
        color: #252525;
    }
    &__text {
        max-width: 240px;
        font-size: 14px;
        span { 
            font-weight: 600;
            color: $goldColor;
        }
    }
}

.about-main {
    &__content {
        margin: 0 auto;
        max-width: 760px;
    }
    &__text {
        max-width: 500px;
        font-size: 18px;
        border: 2px dashed #AD902B;
        border-radius: 20px;
        box-shadow: 5px 5px 10px #d9d9d952;
        padding: 15px 20px;
        align-self: flex-end;
        text-align: center;
        @media (max-width: 579.98px) {
            max-width: 360px;
            font-size: 14px;
        }
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    &__text-bg {
        color: #fff;
        border: 2px solid $decorDarkColor;
        background-color: $decorDarkColor;
        align-self: flex-start;
    }
}

.spollers-faq {
    display: flex;
    flex-direction: column;
    &__item {
        display: flex;
        width: 600px;
        flex-direction: column;
        border: 2px solid #AD902B;
        box-shadow: 5px 5px 5px #00000027;
        border-radius: 20px;
        padding: 20px;
        gap: 30px;    
        &:not(:last-child) {
            margin-bottom: 30px;
        }
        @media (max-width: 767.98px) {
            width: 400px;
            gap: 20px;
        }
        @media (max-width: 479.98px) {
            width: 300px;            
            padding: 15px;
        }
        @media (max-width: 319.98px) {
            width: 240px;
        }
    }
    &__title {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        text-align: left;
        color: #252525;
        font-weight: 600;
        text-transform: uppercase;
        @media(max-width: 767.98px) {
            text-transform: none;
        }
        svg {
            flex: 0 0 18px;
            fill: $footerDecor;
            margin-left: 10px;
            transition: transform 0.9s ease 0s;
            &:hover {
                cursor: pointer;
            }
        }
        &._active {
            color: #AD902B;
        }
    }
    &__body {
        display: none;
        font-weight: 500;
        line-height: 1.2;
        color: #484848;
        &._activeText {
            display: flex;
        }
    }
}

.svg-rotate {
    transform: rotate(-180deg);
}


.error-popup-form {
    text-align: center;
    margin-bottom: 20px;
    padding: 0 20px;
    color: #AD902B;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
}






