.newReview {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    max-height: 450px;
    background-color: #f1ede7;
    overflow: hidden;
    border-radius: 20px;
    padding: 30px 20px;
    border: 2px solid #f1ede7;
    box-shadow: 8px 8px 8px #d9d9d92b;
    @media(max-width: 371.98px) {
        padding: 20px 10px;
    }
}

.body {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.title {
    font-weight: 500;
    color: #252525;
    line-height: 1.2;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.rating {
    display: flex;
    align-items: center;
    gap: 5px;
}

.currentRate {
    font-size: 24px;
    font-weight: 500;
    margin-left: 10px;
    color: #7f766a;
}

.itemRate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    svg {
        transition: all 0.6s ease 0s;        
        }
    &:hover {
        svg {
            fill: #e8c445;
            transform: scale(1.3);
        }
        .number {
            color: #fff;
        }
    }
}

.star {
    font-size: 28px;  
    fill: #fff;
}

.currentStar {
    font-size: 28px;
    fill: #e8c445;
}

.number {
    position: absolute;
    color: #7f766a;
    font-weight: 500;
    font-size: 10px;
}

.textarea {
    resize: none;
    border-radius: 20px;
    border: 2px solid #f7f6f6;
    padding: 20px;
    outline: none;
    color: #7f766a;
    overflow: hidden;
    font-size: 14px;
    &::placeholder {
        color: #7f766a;
        font-size: 14px;
    }
}

.btn {
    display: block;
    width: 100%;
    font-weight: 600;
    background-color: inherit;
    border: 2px solid #fff;
    color: #7f766a;
    padding: 15px 10px;
    border-radius: 20px;
    box-shadow: 3px 3px 3px #7f766a4a;
    transition: all 0.6s ease 0s;
    text-transform: uppercase;
    letter-spacing: 0.08rem;
    &:hover {
        border: 2px solid #7f766a;
        background-color: #7f766a;    
        color: #fff;
    }
}