.loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.loaderLogo {
    position: relative;
    width: 120px;
    height: 120px;
    img {
        position: absolute;
        top: 0;
        left: 0;        
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}