$blackColor: #000000;
$mainColor: #252525;
$darkGreyColor: #484848;
$lightGreyColor: #fefbfb;

$decorColor: #196fd7;
$decorDarkColor: #1560BD;
$footerDecor: #7f766a;

$footerColor: #f1ede7;
$headerLineColor: #f1ede7;
$whiteColor: #ffffff;
$goldColor: #AD912B;