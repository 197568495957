.popupBlock {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: #252525c1;
    z-index: 50;
    padding: 0 20px;
}
.popupImage {
    position: relative;
    display: flex;
    margin: 100px auto;
    border-radius: 20px;
    border: 1px solid #25252544;
    box-shadow: 5px 5px 5px #2525257a;
    z-index: 3;
    width: 740px;
    max-height: 440px;
    background-color: #fff;
    padding: 20px;    
    overflow: hidden;
    svg {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        width: 42px;
        height: 42px;
        fill: #484848;
        transition: fill 0.6s ease 0s;
        z-index: 3;            
        border-radius: 50%;
        background-color: #ffffffdc;
        padding: 10px;
        @media(max-width: 379.98px) {
            right: 5px;
            top: 5px;
        }
        &:hover {
            fill: #252525;
            background-color: #fff;
        }
    }
    @media(max-width: 991.98px) {
        margin: 150px auto;
        max-width: 640px;
    }
    @media(max-width: 767.98px) {        
        margin: 180px auto;
    }
    @media(max-width: 379.98px) {
        padding: 10px;
    }
}
 
.image {
    position: relative;
    width: 700px;
    max-width: 100%;
    height: 100%;
    border-radius: 20px;
    cursor: pointer;
    @media(max-width: 991.98px) {
        width: 600px;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
