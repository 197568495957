.content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 92vw;
    @media(max-width: 767.98px) {
        flex-direction: column;
        width: 100%;
    }
}

.aside {
    padding: 20px 60px 20px 10px;
    @media(max-width: 991.98px) {
        padding: 20px 40px 20px 10px;
    }
    @media(max-width: 767.98px) {
        padding: 20px 10px;
    }
}

.asideTop {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    gap: 15px;
    color: #252525;
    font-size: 16px;
    font-weight: 500;
    @media(max-width: 767.98px) {
        gap: 8px;
        font-size: 12px;
        margin-bottom: 25px;
    }
}

.asideMenu {
    display: flex;
    flex-direction: column;
    gap: 30px;
    @media(max-width: 991.98px) {
        gap: 20px;
    }
    @media(max-width: 767.98px) {
        gap: 15px;
    }
}

.asideMenuItem {
    color: #616161b6;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    &:hover {
        color: #000;
    }
}

.asideMenuItemBlack {
    color: #000;
}

.asideMenuItem,
.asideMenuItemBlack {    
    font-size: 20px;
    font-weight: 500;
    @media(max-width: 991.98px) {
        font-size: 18px;
    }

    @media(max-width: 767.98px) {
        font-size: 16px;
    }

    @media(max-width: 379.98px) {
        font-size: 14px;
    }    
}

.body {
    min-height: 240px;
    border-left: 2px solid #61616124;
    padding: 20px 0 20px 88px;
    @media(max-width: 991.98px) {
        padding: 20px 0 20px 60px;
    }
    @media(max-width: 767.98px) {
        padding: 40px 10px 20px;
        border-left: 0;
        border-top: 2px solid #61616124;
        min-height: 0;
    }
    @media(max-width: 379.98px) {
        padding: 30px 10px 20px;
    }
}

.hidden {
    display: none;
}

.ordersList {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 30px;
    @media(max-width: 767.98px) {
        gap: 20px;
    }
}

.orderInfo {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.orderTitle, 
.orderTitleGold {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 15px;
    font-size: 14px;
    width: 300px;
    cursor: pointer;
    span {
        font-size: 16px;
        font-weight: 500;
    }
    @media(max-width: 991.98px) {
        span {
            font-size: 14px;
        }
        font-size: 12px;
    }
    @media(max-width: 379.98px) {
        span {
            font-size: 11px;
        }
        gap: 8px;
        font-size: 10px;
        width: 240px;
    }
}

.orderTitleGold {
    color: #AD912B;
}

.chevron {
    fill: #252525;
}

.rotate {
    transform: rotate(180deg);
    fill: #AD912B;
}

.orderBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.productsList {
    display: flex;
    flex-direction: column;
    gap: 30px;    
}

.productsItem {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
}

.productTop {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    color: #252525;
    width: 280px;
    @media(max-width: 379.98px) {
        font-size: 12px;
        width: 240px;
    }
}

.productBottom {
    display: flex;
    align-items: center;
    gap: 20px;
    @media(max-width: 379.98px) {
        gap: 15px;
        align-items: flex-start;
    }
}

.productImage {
    position: relative;
    width: 120px;
    height: 100px;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }
    @media(max-width: 379.98px) {
        width: 100px;
        height: 80px;
    }
}

.productInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.infoLine, 
.infoProductLine {
    display: flex;
    white-space: nowrap;    
    font-size: 12px;
}

.infoProductLine {
    justify-content: space-between;
    font-weight: 500;
    color: #484848;
    gap: 8px;
    @media(max-width: 379.98px) {
        font-size: 10px;
    }
}

.total {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
    padding: 0 0 20px 149px;
    border-bottom: 3px dashed #61616124;
    white-space: nowrap;
    @media(max-width: 379.98px) {
        padding: 0 0 20px 10px;
        font-size: 12px;
        border-bottom: 2px dashed #61616124;
    }
}

.actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.deleteAccount,
.changePassword,
.updateAddressBtn,
.changeData,
.deleteAddressBtn,
.cancelBtn,
.showOrders {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 15px 30px;
    border-radius: 20px;
    font-weight: 600;
    transition: all 0.4s ease 0s;
    @media(max-width: 379.98px) {
        font-size: 13px;
        padding: 10px 20px;
    }
}

.deleteAccount,
.deleteAddressBtn,
.cancelBtn {
    border: 2px solid #252525;
    box-shadow: 3px 3px 3px #9c9c9c56;
    &:hover {
        color: #fff;
        background-color: #252525;
    }
}

.changePassword,
.updateAddressBtn,
.changeData,
.showOrders {
    color: #1560BD;
    border: 2px solid #1560BD;
    box-shadow: 3px 3px 3px #4848481b;

    &:hover {
        color: #fff;
        background-color: #1560BD;
    }
}

.userInfo {
    display: flex;
    flex-direction: column;
    gap: 60px;
    width: 300px;
    @media(max-width: 379.98px) {
        width: 240px;
    }
}

.colorGold {
    color: #AD912B;
    font-size: 14px;
}

.userInfoTop,
.userAddress {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.userAddress {
    margin-bottom: 15px;
}

.paragraphs {     
    p {
        padding-left: 22px;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }    
}

.addressItem {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.infoLine,
.addressTitle {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    color: #252525;
    gap: 8px;
    @media(max-width: 379.98px) {
        font-size: 13px;
    }
}

.addressTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    cursor: pointer;
}

.addressLine {
    display: flex;
    flex-wrap: wrap;
    max-width: 290px;
    font-size: 14px;
    line-height: 1.2;
    color: #484848;
    @media(max-width: 379.98px) {
        font-size: 12px;
    }
}

.newAddress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;    
    color: #484848;
    font-weight: 500;
    @media(max-width: 379.98px) {
        font-size: 13px;
        justify-content: flex-start;
    }
}

.addAddress {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 12px;
    border-radius: 15px;
    border: 2px solid #1560BD;
    box-shadow: 3px 3px 3px #4848481b;
    font-weight: 600;
    transition: all 0.6s ease 0s;
    fill: #1560BD;
    &:hover {
        fill: #fff;
        background-color: #1560BD;
    }
}

.alert {
    font-size: 14px;
    font-weight: 500;
    color: #AD912B;
}