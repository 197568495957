.brandProducts {
    padding: 14px 20px;
    background-color: #fff;
    border: 2px solid #1560BD;
    border-radius: 20px;
    box-shadow: 2px 3px 3px #d9d9d95f;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.6s ease 0s;

    &:hover {
        background-color: #1560BD;
        color: #fff;
    }
}