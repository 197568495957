.updateProduct {
    position: relative;
    margin: 0 auto;
    max-width: 600px;
    border: 2px solid #7f766a;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 5px 5px 5px #7f766a52;
    margin-bottom: 100px;

    @media(max-width: 371.98px) {
        overflow: hidden;
        width: 250px;
    }

    svg {
        fill: #7f766a;
        transition: all 0.6s ease 0s;

        &:hover {
            fill: #252525;
        }
    }
}

.formProduct {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media(max-width: 371.98px) {
        gap: 15px;
    }
}

.formInput,
.formInputSmall,
.formSelectBrands,
.formSelectTypes {
    font-size: 13px;
    height: 32px;
    border: 2px solid #dcdcdc;
    border-radius: 20px;
    padding: 0 10px;
    outline: none;

    &:not(:last-child) {
        margin-right: 15px;
    }

    @media (max-width: 602.98px) {
        font-size: 12px;
        margin-right: 0;
    }
}

.formInput {
    width: 360px;
    @media(max-width: 602.98px) {
        width: 240px;
    }
    @media(max-width: 371.98px) {
        width: 190px;
    }
}

.formInputSmall {
    max-width: 150px;
}

.formInputCheckbox {
    position: absolute;
    opacity: 0;
}

.formSelectTypes,
.formSelectBrands {
    width: 200px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.formSelectBrands {
    z-index: 4;
}

.formSelectTypes {
    z-index: 2;
}

.formFile {
    max-width: 260px;
    font-size: 14px;
    color: #AD912B;
    cursor: pointer;

    @media (max-width: 602.98px) {
        font-size: 12px;
    }
}

.line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: 602.98px) {
        flex-direction: column;
        gap: 8px;
        align-items: start;
    }
}

.lineImg {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    @media(max-width: 371.98px) {
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
    }
}

.checkboxes {
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin: 10px 0 10px 0;
}

.formLineCheckbox {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
}

.imgBox {
    position: relative;
    height: 80px;
    @media(max-width: 479.98px) {
        height: 50px;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    svg {
        position: absolute;
        right: 0;
        top: 0;
        width: 24px;
        height: 24px;
        z-index: 0;
        background-color: #ffffffbd;
        padding: 5px;
        border-radius: 0 0 0 5px;
        cursor: pointer;
        &:hover{
            transform: scale(1.3);
        }
    }
}

.label,
.formLabelCheckbox,
.formLabelChecked {
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;

    @media (max-width: 602.98px) {
        font-size: 12px;
        margin-right: 0;
    }
}

.formLabelCheckbox,
.formLabelChecked {
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border: 2px solid #1560BD;
        background-color: #fff;
        right: -34px;
        border-radius: 5px;
    }
}

.formLabelChecked {
    &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 2px;
        background-color: #1560BD;
        right: -30px;
    }
}

.textarea {
    font-size: 13px;
    overflow: auto;
    resize: none;
    outline: none;
    border: 2px solid #dcdcdc;
    padding: 15px;
    min-height: 140px;
    width: 100%;
    @media(max-width: 602.98px) {
        font-size: 12px;
    }
}

.button {
    display: block;
    font-size: 18px;
    font-weight: 600;
    color: #252525;
    padding: 15px 20px;
    border-radius: 20px;
    border: 2px solid #1560BD;
    background-color: #fff;
    outline: none;
    box-shadow: 3px 3px 3px #7f766a3e;
    transition: all 0.6s ease 0s;

    &:hover {
        color: #fff;
        border: 2px solid #1560BD;
        background-color: #1560BD;
    }

    @media (max-width: 579.98px) {
        font-size: 16px;
    }
}

.close {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 1.6rem;
    cursor: pointer;
}

.infoButton,
.slideButton {
    padding: 10px 15px;
    border-radius: 20px;
    border: 2px solid #252525;
    color: #252525;
    font-size: 14px;
    font-weight: 600;
    box-shadow: 3px 3px 3px #7f766a3e;
    outline: none;
    transition: all 0.6s ease 0s;

    &:hover {
        color: #fff;
        background-color: #252525;
    }
}

.typeOptions {
    position: absolute;
    width: 200px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-left: 2px solid #f4f4f4;
    border-right: 2px solid #f4f4f4;
    border-bottom: 2px solid #f4f4f4;
    box-shadow: 3px 3px 3px #f4f4f4a8;
    border-radius: 0 0 20px 20px;
    overflow: hidden;
    z-index: 1;
    top: 326px;
    left: 335px;

    @media(max-width: 602.98px) {
        left: 20px;
        top: 520px;
    }

    @media(max-width: 379.98px) {
        top: 500px;
    }
}

.brandOptions {
    position: absolute;
    width: 200px;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-left: 2px solid #f4f4f4;
    border-right: 2px solid #f4f4f4;
    border-bottom: 2px solid #f4f4f4;
    box-shadow: 3px 3px 3px #f4f4f4a8;
    border-radius: 0 0 20px 20px;
    overflow: hidden;
    z-index: 3;
    top: 326px;
    left: 77px;

    @media(max-width: 602.98px) {
        left: 20px;
        top: 458px;
    }
    @media(max-width: 379.98px) {
        top: 440px;
    }
}

.option {
    cursor: pointer;
    padding: 10px;
    font-size: 14px;
    background-color: #fff;

    &:hover {
        background-color: #f4f4f4;
        color: #AD912B;
    }

    @media (max-width: 602.98px) {
        font-size: 12px;
    }
}