.actions {
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formTitle {
    margin-bottom: 50px;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    color: #252525;
    text-align: center;
    @media (max-width: 767.98px) {
        font-size: 16px;
        max-width: 240px;
    }
}

.formBody {
    display: grid;
    grid-template-columns: repeat(4, 100px);
    justify-content: center;
    gap: 20px;
    @media (max-width: 767.98px) {
        grid-template-columns: repeat(2, 100px);
    }
}

.formActions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    gap: 20px;
    @media (max-width: 767.98px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.buttonCreate,
.buttonUpdate,
.buttonDelete {
    padding: 20px;
    font-weight: 600;
    background-color: #fff;
    color: #252525;
    border-radius: 20px;
    transition: all 0.3s ease 0s;
}

.buttonCreate {
    border: 2px solid #6b645a;

    &:hover {
        color: #fff;
        background-color: #6b645a;
    }
}

.buttonUpdate {
    border: 2px dashed #6b645a;

    &:hover {
        color: #fff;
        background-color: #6b645a;
    }
}

.buttonDelete {
    border: 2px solid #252525;

    &:hover {
    background-color: #252525;
    color: #fff;  
    }
}

.button {
    font-weight: 600;
    border: 2px solid #252525;
    border-radius: 20px;
    width: 100px;
    height: 100px;
    transition: all 0.3s ease 0s;
    &:hover {
        border: 2px solid #AD912B;
        transform: scale(1.1);
    }
}

.closePopup {
    display: flex;
    width: 80px;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-bottom: 50px;
    font-size: 24px;
    font-weight: 500;
    color: #252525;
    gap: 5px;
    transition: all 0.3s ease 0s;
    svg {
        fill: #252525;
        font-size: 20px;    
    }        
    &:hover {
        svg {
            fill: #AD912B;            
        }
    }
    @media(max-width: 767.98px) {
        font-size: 20px;
        svg {
            font-size: 16px;
        }
    }
}

.deliveryBlock,
.dataPaymentBlock,
.productsListBlock,
.promocodesBlock {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin: 0 auto;
    h4 {
        align-self: center;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.4;
        max-width: 260px;
        text-decoration: underline;
    }
}

.deliveryItems,
.dataPaymentItems {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.deliveryItems {
    max-width: 400px;
    flex-wrap: wrap;
}

.deliveryBlock {    
    max-width: 400px;
}

.deliveryItem,
.dataPaymentItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
}

.deliveryTitle,
.dataPaymentTitle {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    @media (max-width: 379.98px) {
        font-size: 14px;
    }
}

.deliveryPrice,
.dataPaymentValue {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #484848;
    @media (max-width: 379.98px) {
        font-size: 14px;
    }
    svg {
        fill: #6b645a;
        cursor: pointer;
        &:hover {
            fill: #484848;
        }
    }
}

.upBtn, 
.subBtn,
.cancelBtn {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 15px 20px;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 3px 3px 3px #9c9c9c56;
    transition: all 0.6s ease 0s;
    max-width: 260px;
    width: 100%;
}
.upBtn,
.subBtn {
    background-color: #fff;
    color: #1560BD;
    border: 2px solid #1560BD;       

    &:hover {
        color: #fff;
        border: 2px solid #1560BD;
        background-color: #1560BD;
    }    
}

.subBtn {
    margin-top: 30px;
}

.cancelBtn {
    background-color: #fff;
    color: #252525;
    border: 2px solid #252525;
    &:hover {
        color: #fff;
        border: 2px solid #252525;
        background-color: #252525;
    }
}

.deliveryForm,
.dataPaymentForm,
.logoForm,
.promocodesForm {
    display: flex;
    flex-direction: column;
    gap: 20px; 
}

.logoForm {
    margin-top: 20px;
}


.formLine, 
.formLineList,
.formLogoLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;   
}

.promocodesLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 380px;
    @media(max-width: 479.98px) {    
        flex-direction: column;
        justify-content: center;
        width: 240px;
    }
}

.formLogoLine {
    @media(max-width: 479.98px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.formLine,
.formLinePayment,
.formLogoLine,
.promocodesLine {
    padding: 0 15px;    
}

.formLinePayment {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.formLineList {
    @media(max-width: 991.98px) {
        max-width: 90vw;
        overflow: auto;    
    } 
}

.deliveryLabel,
.dataPaymentLabel,
.logoLabel,
.promocodesLabel {
    font-size: 16px;
    font-weight: 500;
    color: #252525;
    white-space: nowrap;
    @media(max-width: 479.98px) {
        font-size: 14px;
    }
}

.deliveryInput {
    padding: 10px 15px;
    border-bottom: 2px solid #acacacd2;
    outline: none;
    font-size: 16px;
    @media(max-width: 479.98px) {
        width: 50%;
        font-size: 14px;
    }
}

.promocodesInput {
    padding: 10px 15px;
    border-bottom: 2px solid #acacacd2;
    outline: none;
    font-size: 16px;
}

.logoInput {
    padding: 10px 15px;
    border-bottom: 2px solid #acacacd2;
    outline: none;
    font-size: 16px;
    @media(max-width: 479.98px){
        width: 100%;   
        font-size: 14px;     
    }
}

.logoFile {
    display: flex;
    max-width: 260px;
    font-size: 14px;
    color: #AD912B;
    cursor: pointer;

    @media (max-width: 479.98px) {
        font-size: 12px;
        max-width: 240px;
    }
}

.dataPaymentInput {
    padding: 10px 15px;
    border-bottom: 2px solid #acacacd2;
    outline: none;
    font-size: 16px;
    width: 300px;
    @media(max-width: 379.98px) {
        padding: 5px 8px;
        width: 240px;
        font-size: 13px;
    }
}


.deliveriesList,
.paymentsList {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 30px;
    @media(max-width: 479.98px) {
        margin-bottom: 20px;
    }
}

.deliveriesList {
    flex-wrap: wrap;
    @media(max-width: 991.98px) {
        flex-wrap: nowrap;
    }
}

.promocodesList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    margin: 0 auto;
}

.promocodesItem {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 240px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
}

.promocodesName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.promocodesSvg {    
    width: 20px;
    height: 20px;
    transition: all 0.4s ease 0s;
    cursor: pointer;
    &:hover {
        fill: #1560BD;
        transform: scale(1.1);
    }    
}

.deliveryType,
.deliveryTypeActive,
.paymentType,
.paymentTypeActive {
    font-size: 14px;
    font-weight: 500;
    color: #252525;   
    border-radius: 20px;
    border: 2px solid #484848; 
    box-shadow: 3px 3px 3px #d9d9d9ad;
    padding: 15px 10px;
    white-space: nowrap;
    cursor: pointer;
    @media(max-width: 479.98px) {
        font-size: 13px;
    }
}

.deliveryTypeActive,
.paymentTypeActive {
    border: 2px solid #AD912B;
}

.logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

}

.logoBox {
    display: flex;
    position: relative;
}

.logoImg {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }
}

.logoText {
    font-size: 24px;
    font-weight: 500;
}

.logoAction {
    position: absolute;
    bottom: -8px;
    right: -15px;
    width: 40px;
    height: 40px;
    background-color: #4545453f;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    fill: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease 0s;
    &:hover {
        background-color: #45454551;
    }
}

.accountAvailableTrue {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: #1560BD;
    padding: 10px;
    border-radius: 20px;
    border: 2px solid #1560BD;
    width: 98px;
}

.accountAvailableFalse {
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: #484848;
    padding: 10px;
    border-radius: 20px;
    border: 2px solid #484848;
    width: 110px;
}

.hidden {
    display: none;
}

.productList {
    display: flex;
    flex-direction: column;
}

.productItem {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;
    border-radius: 15px;
    color: #484848;
    &:hover {
        background-color: #f7f7f7a0;
        color: #252525;
        svg {
            fill: #1561bdd2;
        }
    }
    svg {
        fill: #484848;
        flex: 0 0 30px;
        height: 30px;
        cursor: pointer;
        transition: all 0.3s ease 0s;
        &:hover {
            fill: #1560BD;
        }
    }
}

.productInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
    line-height: 1.2;
    @media(max-width: 479.98px) {
        font-size: 10px;
    }
}

.productImg {
    position: relative;
    flex: 0 0 50px;
    height: 50px;
    img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.loadingText,
.alertText {
    text-align: center;
    font-size: 16px;
    color: #AD912B;
    font-weight: 500;
}


